import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody, Row, CardHeader, Table, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from "reactstrap";
import RatingTooltip from "react-rating-tooltip";
import Col from "components/Shared/Col";
import PropTypes from 'prop-types';
import { route, routes } from 'helpers/routeHelper';
import { startNotaryMeeting, getFullOrder } from 'store/actions';
import { showSuccess, showError, openInNewWindow } from "helpers/utilHelper";
import NotaryRating from '../Form/NotaryRating';
import { perms, useAccess } from "context/access";
import OrderNotary from "model/orderNotary";
import { CANNOT_DELETE_SINGLE_ORDER_NOTARY, ORDER_NOTARY_STATUS_INVALID_FOR_REMOVAL, ServerErrorException, UNABLE_SEND_NEW_MEETING_PARTICIPANT_NOTIF } from 'helpers/errorHelper';
import Order from 'model/order';
import { formatTimestampTz, formats } from "helpers/dateHelper";
import Confirmation from "components/Shared/Confirmation";
import { removeNotary } from 'helpers/backendHelper';
import { getOrderFees } from 'store/orderFees/actions';
import config from 'config';
import { useAuth } from 'context/auth';
import checkNotary from 'assets/images/activ-notary-info.svg';

const SectionNotaryInfo = ({ toggleEditMode }) => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  // router hook that helps redirect
  const navigate = useNavigate();

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  // get the auth user
  const { user } = useAuth();

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const { order } = useSelector(state => state.Order.Single);

  const { notaryMeetingStarted, notaryMeetingLink, notaryMeetingStartError } = useSelector(state => state.Order.Single);

  const [isRemoveNotaryConfirmationVisible, setIsRemoveNotaryConfirmationVisible] = useState(false);
  const [selectedNotary, setSelectedNotary] = useState(null);

  const canRemoveNotary = (notary) => notary && notary.status <= OrderNotary.STATUS_DOCS_PRINTED && order.notaries?.length > 1;

  const hasNotaries = order.notaries?.length > 0;

  const getActiveNotary = () => order.notaries?.filter(n => !n.isInactive)[0];

  const isAwarded = order.notaries?.[0]?.id || order.notaries?.[1]?.id;

  const startMeeting = () => dispatch(startNotaryMeeting(order.id));

  const activeNotary = getActiveNotary();

  const orderHasTwoNotary = () => order.notaries?.length > 1


  /********** EFFECTS **********/

  // runs whenever the 'notaryMeetingStarted' flag changes
  // which happens after a start meeting attempt
  useEffect(() => {
    if (notaryMeetingStarted === true) {
      openInNewWindow(notaryMeetingLink);
    }
  }, [notaryMeetingStarted]);

  useEffect(() => {
    if (notaryMeetingStartError) {
      if (notaryMeetingStartError instanceof ServerErrorException) {
        if (notaryMeetingStartError.code === UNABLE_SEND_NEW_MEETING_PARTICIPANT_NOTIF) {
          // call has been saved but the notifications could not be sent (at least some of them)
          showError("Unable to send notifications");
          return;
        }
      }
      showError("Unable to initiate call");
    }
  }, [notaryMeetingStartError]);

  const [modal_center, setmodal_center] = useState(false);
  const starStyle = {};

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const displayAppointmentDate = (date, format, notaryIndex) => {
    // Display the Appointment Date/ Time if it was set by notary
    if (date >= OrderNotary.STATUS_APPOINTMENT_SET) return formatTimestampTz(order.notaries?.[notaryIndex]?.meetingTs, format, user.timezone)
    return 'Not set'
  }

  useEffect(() => {
    if (selectedNotary) {
      setIsRemoveNotaryConfirmationVisible(true);
    }
  }, [selectedNotary]);

  const removeNotaryFromOrder = () => {
    removeNotary(order.id, selectedNotary.notaryId)
      .then(res => {
        showSuccess(`Notary ${selectedNotary.notaryFullName} has been removed from order`);
        refreshOrder();
        refreshOrderFees();
      })
      .catch(err => {
        if (err.code == CANNOT_DELETE_SINGLE_ORDER_NOTARY) {
          showError(`Cannot remove single order notary`);
          return;
        }
        if (err.code == ORDER_NOTARY_STATUS_INVALID_FOR_REMOVAL) {
          showError(`Notary status invalid for removal`);
          return;
        }
        showError(`Unable to remove notary ${selectedNotary.notaryFullName} from order`);
      }).finally(() => {
        setSelectedNotary(null);
      })
  }

  /********** OTHER **********/

  const refreshOrder = () => dispatch(getFullOrder(order.id));
  const refreshOrderFees = () => dispatch(getOrderFees(order.id));

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Notary</div>
          </Col>
          {iAmGranted(perms.edit_appointment_date) && isAwarded && <Col xs="auto" className="text-end">
            <Dropdown isOpen={menuIsOpen} toggle={() => setMenuIsOpen(!menuIsOpen)}>
              <DropdownToggle tag="button" className="btn btn-default card-menu-btn">
                <i className="bx bx-dots-horizontal-rounded" />
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem onClick={toggleEditMode}>Edit</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>}
        </Row>
      </CardHeader>
      <CardBody className="pb-4 d-flex flex-column justify-content-between">
        <Table className="section-rows table bt-1">
          <thead>
            {iAmGranted(perms.view_order_notary_status) && (
              <tr>
                <td className="section-row-label">Status</td>
                <td className="section-row-value ps-4">{OrderNotary.getStatusName(getActiveNotary()?.status) || "--"}</td>
              </tr>
            )}
          </thead>
          {[...Array(config.ORDER_MAX_NUM_OF_NOTARIES)].map((item, key) => <>
            <tbody
              key={key}
              className={
                orderHasTwoNotary() &&
                  order.notaries?.[key] &&
                  activeNotary &&
                  order.notaries?.[key].notaryId === activeNotary.notaryId
                  ? "active-notary-row table-notary-info"
                  : "table-notary-info"
              }>
              <tr >
                <td className="section-row-label px-1">
                  Notary
                </td>
                <td className="section-row-value ps-4 px-1">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center pe-3">
                      {!!order.notaries?.[key]?.notaryFullName ? <Link className={
                        orderHasTwoNotary() &&
                          order.notaries?.[key] &&
                          activeNotary &&
                          order.notaries?.[key].notaryId === activeNotary.notaryId
                          ? "text-white-color"
                          : ""
                      } to={route(routes.view_notary, order.notaries?.[key]?.notaryId)}>{order.notaries?.[key]?.notaryFullName}</Link> : 'Unassigned'}
                      {orderHasTwoNotary() && order.notaries?.[key] && activeNotary && order.notaries?.[key].notaryId === activeNotary.notaryId && (<>
                        <img src={checkNotary} id='template-icon' className="mx-2" alt="Active Notary" />
                        <UncontrolledTooltip placement="top" target='template-icon'>Active notary </UncontrolledTooltip></>
                      )}
                    </div>
                    {iAmGranted(perms.assign_notaries) && order.status >= Order.STATUS_NEW && !order.notaries?.[key] && (
                      <Button
                        color="primary"
                        onClick={() => navigate(route(routes.view_order_notary, order.id))}
                      >
                        Assign
                      </Button>
                    )}
                    {iAmGranted(perms.remove_notary) && canRemoveNotary(order.notaries?.[key]) && (
                      <Button
                        color="danger"
                        onClick={() => setSelectedNotary(order.notaries?.[key])}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
              {!!order.notaries?.[key] && <>
                <tr>
                  <td className="section-row-label px-1">Lead Comment</td>
                  <td className="section-row-value ps-4 px-1"> {!!order.notaries?.[key]?.comments ? order.notaries?.[key]?.comments : '---'}</td>
                </tr>
                <tr>
                  <td className="section-row-label px-1">Appointment Date</td>
                  <td className="section-row-value ps-4 px-1">{displayAppointmentDate(order.notaries?.[key]?.status, formats.APPOINTMENT_DATE_FORMAT, key)}</td>
                </tr>
                <tr>
                  <td className="section-row-label px-1">Appointment Time</td>
                  <td className="section-row-value ps-4 px-1">{displayAppointmentDate(order.notaries?.[key]?.status, formats.APPOINTMENT_TIME_FORMAT, key)}</td>
                </tr>
                {iAmGranted(perms.create_notary_calls) && (
                  <tr>
                    <td className="section-row-label px-1">Video Call</td>
                    <td className="section-row-value ps-4 px-1">
                      <Button color="primary" className="call-btn w-100" onClick={startMeeting}>
                        Start Video Call <i className="bx bx-video" />
                      </Button>
                    </td>
                  </tr>
                )}
                {order.notaries?.[key]?.id && !!order.notaries?.[key]?.ratings?.general?.score && <tr>
                  <td className="section-row-label px-1">Rating</td>
                  <td className="section-row-value d-flex justify-content-start ps-4 px-1">
                    <RatingTooltip
                      max={5}
                      defaultRating={parseInt(order.notaries[key].ratings.general.score)}
                      disabled
                      ActiveComponent={
                        <i
                          className="mdi mdi-star-outline text-warning mb-2"
                          style={starStyle}
                        />
                      }
                      InActiveComponent={
                        <i
                          className="mdi mdi-star-outline text-muted mb-2"
                          style={starStyle}
                        />
                      }
                    />
                  </td>
                </tr>}
              </>}
            </tbody>
          </>
          )}
        </Table>
        <div className="d-flex flex-row-reverse justify-content-between mt-3 gap-3 flex-wrap">
          {iAmGranted(perms.review_notaries) && isAwarded &&
            <>
              <button type="button" className='btn btn-secondary notary-review-btn' onClick={() => { tog_center(); }}>Notary Review <i className="mdi mdi-star-outline ms-1"></i></button>
              <NotaryRating isModalOpen={modal_center} toggleModal={() => { tog_center(); }} />
            </>
          }
          {
            hasNotaries &&
            <Link to={route(routes.view_order_notary_information, order.id)} className='btn btn-primary'>View More Details <i className="mdi mdi-arrow-right ms-1"></i></Link>
          }
        </div>
      </CardBody>
    </Card>
    {
      isRemoveNotaryConfirmationVisible && <Confirmation
        confirmBtnText="Confirm"
        reverseButtons={false}
        onConfirm={() => {
          setIsRemoveNotaryConfirmationVisible(false)
          removeNotaryFromOrder();
        }}
        onCancel={() => {
          setIsRemoveNotaryConfirmationVisible(false)
          setSelectedNotary(null);
        }}>
        <span style={{ color: '#556EE6' }}>Are you sure you want to remove notary <span className="font-weight-semibold">{selectedNotary.notaryFullName}</span> from this signing?</span>
      </Confirmation>
    }
  </React.Fragment >
}

SectionNotaryInfo.propTypes = {

  toggleEditMode: PropTypes.func,
};

export default SectionNotaryInfo;