import React from 'react';
import PropTypes from 'prop-types';
import ActivityLog from 'model/activityLog';
import OrderVehicleUpdated from './OrderVehicle/OrderVehicleUpdated';
import OrderVehicleCreated from './OrderVehicle/OrderVehicleCreated';
import OrderVehicleDeleted from './OrderVehicle/OrderVehicleDeleted';
import OrderCreated from './Order/OrderCreated';
import OrderSubmitted from './Order/OrderSubmitted';
import OrderCancelled from './Order/OrderCancelled';
import OrderStatusChanged from './Order/OrderStatusChanged';
import OrderContractChanged from './Order/OrderContractChanged';
import OrderSchedulerAssigned from './Order/OrderSchedulerAssigned';
import OrderDuplicateReviewStatusChanged from './Order/OrderDuplicateReviewStatusChanged';
import OrderDocCreated from './OrderDoc/OrderDocCreated';
import NotImplemented from './NotImplemented';
import OrderSignerCreated from './OrderSigner/OrderSignerCreated';
import OrderSignerUpdated from './OrderSigner/OrderSignerUpdated';
import OrderSignerDeleted from './OrderSigner/OrderSignerDeleted';
import OrderSignerSmsOptIn from './OrderSigner/OrderSignerSmsOptIn';
import OrderSignerSmsOptOut from './OrderSigner/OrderSignerSmsOptOut';
import OrderSignerOrderCreatedMailSent from './OrderSigner/OrderSignerOrderCreatedMailSent';
import OrderSignerOrderCreatedSmsSent from './OrderSigner/OrderSignerOrderCreatedSmsSent';
import OrderSignerSmsConsentSent from './OrderSigner/OrderSignerSmsConsentSent';
import OrderSignerVerificationCreated from './OrderSignerVerification/OrderSignerVerificationCreated';
import OrderSignerVerificationChecked from './OrderSignerVerification/OrderSignerVerificationChecked';
import OrderDocUpdated from './OrderDoc/OrderDocUpdated';
import OrderDocSigned from './OrderDoc/OrderDocSigned';
import OrderDocAccepted from './OrderDoc/OrderDocAccepted';
import OrderDocRejected from './OrderDoc/OrderDocRejected';
import OrderDocDeleted from './OrderDoc/OrderDocDeleted';
import OrderDocStatusChanged from './OrderDoc/OrderDocStatusChanged';
import OrderDocViewed from './OrderDoc/OrderDocViewed';
import OrderDocDownloaded from './OrderDoc/OrderDocDownloaded';
import OrderDocReadyToSign from './OrderDoc/OrderDocReadyToSign';
import OrderSealStarted from './OrderSeal/OrderSealStarted';
import OrderSealSuccessful from './OrderSeal/OrderSealSuccessful';
import OrderSealFailed from './OrderSeal/OrderSealFailed';
import VidRequestAutoStarted from './VidRequest/VidRequestAutoStarted';
import VidRequestAutoSuccess from './VidRequest/VidRequestAutoSuccess';
import VidRequestAutoFailed from './VidRequest/VidRequestAutoFailed';
import VidRequestAssistStarted from './VidRequest/VidRequestAssistStarted';
import VidRequestAssistSuccess from './VidRequest/VidRequestAssistSuccess';
import VidRequestAssistFailed from './VidRequest/VidRequestAssistFailed';
import VidRequestCreated from './VidRequest/VidRequestCreated';
import VidRequestImagesUploaded from './VidRequest/VidRequestImagesUploaded';
import MeetingCreated from './Meeting/MeetingCreated';
import MeetingStarted from './Meeting/MeetingStarted';
import MeetingEnded from './Meeting/MeetingEnded';
import MeetingParticipantJoined from './Meeting/MeetingParticipantJoined';
import MeetingParticipantLeft from './Meeting/MeetingParticipantLeft';
import OrderNotaryAssigned from "./OrderNotary/OrderNotaryAssigned";
import OrderNotaryDocsReceived from "./OrderNotary/OrderNotaryDocsReceived";
import OrderNotaryAppointmentSet from "./OrderNotary/OrderNotaryAppointmentSet";
import OrderNotaryAppointmentDeclined from "./OrderNotary/OrderNotaryAppointmentDeclined";
import OrderNotaryDocsPrinted from "./OrderNotary/OrderNotaryDocsPrinted";
import OrderNotaryDocsUploaded from "./OrderNotary/OrderNotaryDocsUploaded";
import OrderNotaryOrderCompleted from "./OrderNotary/OrderNotaryOrderCompleted";
import OrderUncancelled from './Order/OrderUncancelled';
import TermsAccepted from './Order/TermsAccepted';
import OrderReadyForBilling from './OrderPayment/OrderReadyForBilling';
import OrderShippingChanged from './Order/OrderShippingChanged';
import OrderIsRuralChanged from './Order/OrderIsRuralChanged';
import OrderAddedRush from './Order/OrderAddedRush';
import OrderRemovedRush from './Order/OrderRemovedRush';
import OrderAddedVid from './Order/OrderAddedVid';
import OrderRemovedVid from './Order/OrderRemovedVid';
import VidDeleted from './VidRequest/VidDeleted';
import VidSkipped from './VidRequest/VidSkipped';
import OrderIgnoredAccounting from './Order/OrderIgnoredAccounting';
import OrderAddedEsign from './Order/OrderAddedEsign';
import OrderRemovedEsign from './Order/OrderRemovedEsign';
import OrderNotaryAppointmentAccepted from './OrderNotary/OrderNotaryAppointmentAccepted';
import OrderNotaryDeleted from './OrderNotary/OrderNotaryDeleted';
import PaymentRequested from './Payment/PaymentRequested';
import PaymentFailed from './Payment/PaymentFailed';
import PaymentCompleted from './Payment/PaymentCompleted';
import NotarySmsOptIn from './Notary/NotarySmsOptIn';
import NotarySmsOptOut from './Notary/NotarySmsOptOut';
import ServicesSelected from './Order/ServicesSelected';
import MutedChatAllMuted from './MutedChat/MutedChatAllMuted';
import MutedChatAllUnmuted from './MutedChat/MutedChatAllUnmuted';
import MutedChatChannelMuted from './MutedChat/MutedChatChannelMuted';
import MutedChatChannelUnmuted from './MutedChat/MutedChatChannelUnmuted';
import OrderFeeCreated from './OrderFee/OrderFeeCreated';
import OrderFeeUpdated from './OrderFee/OrderFeeUpdated';
import OrderFeeDeleted from './OrderFee/OrderFeeDeleted';
import UserSchedulerAssignedToOrderMailSent from './User/UserSchedulerAssignedToOrderMailSent';
import UserNotaryBidAcceptedMailSent from './User/UserNotaryBidAcceptedMailSent';
import UserNotaryAppointmentDeclinedMailSent from './User/UserNotaryAppointmentDeclinedMailSent';
import UserNotaryAppointmentSetMailSent from './User/UserNotaryAppointmentSetMailSent';
import UserMeetingCreatedMailSent from './User/UserMeetingCreatedMailSent';
import UserNotaryDocsReceivedMailSent from './User/UserNotaryDocsReceivedMailSent';
import UserNotaryBidAwardedMailSent from './User/UserNotaryBidAwardedMailSent';
import UserNotaryBidAwardedSmsSent from './User/UserNotaryBidAwardedSmsSent';
import UserOrderCompletedByNotaryMailSent from './User/UserOrderCompletedByNotaryMailSent';
import UserNotaryAppointmentAcceptedMailSent from './User/UserNotaryAppointmentAcceptedMailSent';
import UserNotaryBidExpiredMailSent from './User/UserNotaryBidExpiredMailSent';
import UserNotaryBidRejectedMailSent from './User/UserNotaryBidRejectedMailSent';
import UserNotaryBidDeclinedMailSent from './User/UserNotaryBidDeclinedMailSent';
import UserNotaryMustUploadDocsMailSent from './User/UserNotaryMustUploadDocsMailSent';
import UserNotaryStartOrderMailSent from './User/UserNotaryStartOrderMailSent';
import UserNewNotaryBidMailSent from './User/UserNewNotaryBidMailSent';
import UserTrackingInfoAddedMailSent from './User/UserTrackingInfoAddedMailSent';
import UserTrackingInfoAddedSmsSent from './User/UserTrackingInfoAddedSmsSent';
import UserNewNotaryBidSmsSent from './User/UserNewNotaryBidSmsSent';
import UserNotaryAppointmentAcceptedSmsSent from './User/UserNotaryAppointmentAcceptedSmsSent';
import UserNotaryAppointmentDeclinedSmsSent from './User/UserNotaryAppointmentDeclinedSmsSent';
import UserNotaryBidRejectedSmsSent from './User/UserNotaryBidRejectedSmsSent';
import UserSmsConsentSent from './User/UserSmsConsentSent';
import DealerStoreCreated from './DealerStore/DealerStoreCreated';
import DealerStoreDeleted from './DealerStore/DealerStoreDeleted';
import DealerStoreFinanceUpdated from './DealerStore/DealerStoreFinanceUpdated';
import DealerStoreContact2Updated from './DealerStore/DealerStoreContact2Updated';
import DealerStoreContactUpdated from './DealerStore/DealerStoreContactUpdated';
import DealerStoreInfoUpdated from './DealerStore/DealerStoreInfoUpdated';
import DealerStoreBillingUpdated from './DealerStore/DealerStoreBillingUpdated';
import DealerStorePaymentPlanChanged from './DealerStore/DealerStorePaymentPlanChanged';
import DealerStoreSubscriptionCancelled from './DealerStore/DealerStoreSubscriptionCancelled';
import DealerStoreMonthlyUsageReportSent from './DealerStore/DealerStoreMonthlyUsageReportSent';
import DealerStorePaymentSetupCreated from './DealerStore/DealerStorePaymentSetupCreated';
import DealerStorePaymentSetupDeleted from './DealerStore/DealerStorePaymentSetupDeleted';
import DealerStorePaymentSetupFinished from './DealerStore/DealerStorePaymentSetupFinished';
import OrderDocSignedRebuilt from './OrderDoc/OrderDocSignedRebuilt';
import OrderDocFieldsReset from './OrderDoc/OrderDocFieldsReset';
import OrderUnderReviewStatusChanged from './Order/OrderUnderReviewStatusChanged';
import OrderOnHoldReviewStatusChanged from './Order/OrderOnHoldReviewStatusChanged';
import OrderAccountingReviewStatusChanged from './Order/OrderAccountingReviewStatusChanged';
import UserPageRejectedMailSent from './User/UserPageRejectedMailSent';
import UserPageRejectedSmsSent from './User/UserPageRejectedSmsSent';
import UserDocRejectedSmsSent from './User/UserDocRejectedSmsSent';
import UserDocRejectedMailSent from './User/UserDocRejectedMailSent';

const ActivityEntry = props => {

  const { log } = props;

  let Component = null;

  switch (log.event) {

    /***** ORDER *****/

    case ActivityLog.EVENT_ORDER_CREATED:
      Component = OrderCreated;
      break;
    case ActivityLog.EVENT_ORDER_SUBMITTED:
      Component = OrderSubmitted
      break;
    case ActivityLog.EVENT_ORDER_CANCELLED:
      Component = OrderCancelled;
      break;
    case ActivityLog.EVENT_ORDER_SEAL_STARTED:
      Component = OrderSealStarted;
      break;
    case ActivityLog.EVENT_ORDER_SEAL_SUCCESS:
      Component = OrderSealSuccessful;
      break;
    case ActivityLog.EVENT_ORDER_SEAL_FAILED:
      Component = OrderSealFailed;
      break;
    case ActivityLog.EVENT_ORDER_STATUS_CHANGED:
      Component = OrderStatusChanged;
      break;
    case ActivityLog.EVENT_ORDER_CONTRACT_DETAILS_CHANGED:
      Component = OrderContractChanged;
      break;
    case ActivityLog.EVENT_ORDER_SCHEDULER_ASSIGNED:
      Component = OrderSchedulerAssigned;
      break;
    case ActivityLog.EVENT_ORDER_SHIPPING_CHANGED:
      Component = OrderShippingChanged;
      break;
    case ActivityLog.EVENT_ORDER_MARKED_AS_READY_FOR_BILLING:
      Component = OrderReadyForBilling;
      break;
    case ActivityLog.EVENT_ORDER_IS_RURAL_CHANGED:
      Component = OrderIsRuralChanged;
      break;
    case ActivityLog.EVENT_ORDER_RUSH_SERVICE_ADDED:
      Component = OrderAddedRush;
      break;
    case ActivityLog.EVENT_ORDER_RUSH_SERVICE_REMOVED:
      Component = OrderRemovedRush;
      break;
    case ActivityLog.EVENT_ORDER_ADDED_VID_REQUEST:
      Component = OrderAddedVid;
      break;
    case ActivityLog.EVENT_ORDER_REMOVED_VID_REQUEST:
      Component = OrderRemovedVid;
      break;
    case ActivityLog.EVENT_ORDER_IGNORED_ACCOUNTING:
      Component = OrderIgnoredAccounting;
      break;
    case ActivityLog.EVENT_ORDER_UNCANCELLED:
      Component = OrderUncancelled;
      break;
    case ActivityLog.EVENT_ORDER_TERMS_ACCEPTED:
      Component = TermsAccepted;
      break;
    case ActivityLog.EVENT_ORDER_ADDED_ESIGN_SERVICE:
      Component = OrderAddedEsign;
      break;
    case ActivityLog.EVENT_ORDER_REMOVED_ESIGN_SERVICE:
      Component = OrderRemovedEsign;
      break;
    case ActivityLog.EVENT_ORDER_ON_HOLD_REVIEW_STATUS_CHANGED:
      Component = OrderOnHoldReviewStatusChanged;
      break;
    case ActivityLog.EVENT_ORDER_ACCOUNTING_REVIEW_STATUS_CHANGED:
      Component = OrderAccountingReviewStatusChanged;
      break;
    case ActivityLog.EVENT_ORDER_UNDER_REVIEW_STATUS_CHANGED:
      Component = OrderUnderReviewStatusChanged;
      break;
    case ActivityLog.EVENT_ORDER_DUPLICATE_REVIEW_STATUS_CHANGED:
      Component = OrderDuplicateReviewStatusChanged;
      break;
    case ActivityLog.EVENT_ORDER_SERVICES_SELECTED:
      Component = ServicesSelected;
      break;


    /***** ORDER DOC *****/

    case ActivityLog.EVENT_ORDER_DOC_CREATED:
      Component = OrderDocCreated;
      break;
    case ActivityLog.EVENT_ORDER_DOC_UPDATED:
      Component = OrderDocUpdated;
      break;
    case ActivityLog.EVENT_ORDER_DOC_SIGNED:
      Component = OrderDocSigned;
      break;
    case ActivityLog.EVENT_ORDER_DOC_ACCEPTED:
      Component = OrderDocAccepted;
      break;
    case ActivityLog.EVENT_ORDER_DOC_REJECTED:
      Component = OrderDocRejected;
      break;
    case ActivityLog.EVENT_ORDER_DOC_DELETED:
      Component = OrderDocDeleted;
      break;
    case ActivityLog.EVENT_ORDER_DOC_STATUS_CHANGED:
      Component = OrderDocStatusChanged;
      break;
    case ActivityLog.EVENT_ORDER_DOC_SIGNED_REBUILT:
      Component = OrderDocSignedRebuilt;
      break;
    case ActivityLog.EVENT_ORDER_DOC_FIELDS_RESET:
      Component = OrderDocFieldsReset;
      break;
    case ActivityLog.EVENT_ORDER_DOC_VIEWED:
      Component = OrderDocViewed;
      break;
    case ActivityLog.EVENT_ORDER_DOC_DOWNLOADED:
      Component = OrderDocDownloaded;
      break;
    case ActivityLog.EVENT_ORDER_DOC_READY_TO_SIGN:
      Component = OrderDocReadyToSign;
      break;


    /***** ORDER SIGNER *****/

    case ActivityLog.EVENT_ORDER_SIGNER_CREATED:
      Component = OrderSignerCreated;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_UPDATED:
      Component = OrderSignerUpdated;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_DELETED:
      Component = OrderSignerDeleted;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_SMS_OPT_IN:
      Component = OrderSignerSmsOptIn;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_SMS_OPT_OUT:
      Component = OrderSignerSmsOptOut;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_ORDER_CREATED_MAIL_SENT:
      Component = OrderSignerOrderCreatedMailSent;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_ORDER_CREATED_SMS_SENT:
      Component = OrderSignerOrderCreatedSmsSent;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_SMS_CONSENT_SENT:
      Component = OrderSignerSmsConsentSent;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_NOTARY_APPOINTMENT_ACCEPTED_MAIL_SENT:
      Component = OrderSignerSmsConsentSent;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_NOTARY_APPOINTMENT_SET_MAIL_SENT:
      Component = OrderSignerSmsConsentSent;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_NOTARY_APPOINTMENT_SET_SMS_SENT:
      Component = OrderSignerSmsConsentSent;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_ORDER_DOC_CREATED_MAIL_SENT:
      Component = OrderSignerSmsConsentSent;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_ORDER_DOC_CREATED_SMS_SENT:
      Component = OrderSignerSmsConsentSent;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_MEETING_CREATED_MAIL_SENT:
      Component = OrderSignerSmsConsentSent;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_VID_SERVICE_ADDED_MAIL_SENT:
      Component = OrderSignerSmsConsentSent;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_VID_SERVICE_ADDED_SMS_SENT:
      Component = OrderSignerSmsConsentSent;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_DOC_REJECTED_MAIL_SENT:
      Component = OrderSignerSmsConsentSent;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_DOC_REJECTED_SMS_SENT:
      Component = OrderSignerSmsConsentSent;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_PAGE_REJECTED_MAIL_SENT:
      Component = OrderSignerSmsConsentSent;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_PAGE_REJECTED_SMS_SENT:
      Component = OrderSignerSmsConsentSent;
      break;


    /***** VID REQUEST *****/

    case ActivityLog.EVENT_VID_REQUEST_AUTO_STARTED:
      Component = VidRequestAutoStarted;
      break;
    case ActivityLog.EVENT_VID_REQUEST_AUTO_SUCCESS:
      Component = VidRequestAutoSuccess;
      break;
    case ActivityLog.EVENT_VID_REQUEST_AUTO_FAILED:
      Component = VidRequestAutoFailed;
      break;
    case ActivityLog.EVENT_VID_REQUEST_ASSIST_STARTED:
      Component = VidRequestAssistStarted;
      break;
    case ActivityLog.EVENT_VID_REQUEST_ASSIST_SUCCESS:
      Component = VidRequestAssistSuccess;
      break;
    case ActivityLog.EVENT_VID_REQUEST_ASSIST_FAILED:
      Component = VidRequestAssistFailed;
      break;
    case ActivityLog.EVENT_VID_REQUEST_CREATED:
      Component = VidRequestCreated;
      break;
    case ActivityLog.EVENT_VID_REQUEST_IMAGES_UPLOADED:
      Component = VidRequestImagesUploaded;
      break;
    case ActivityLog.EVENT_VID_REQUEST_SKIPPED:
      Component = VidSkipped;
      break;
    case ActivityLog.EVENT_VID_REQUEST_DELETED:
      Component = VidDeleted;
      break;


    /***** MEETING *****/

    case ActivityLog.EVENT_MEETING_CREATED:
      Component = MeetingCreated;
      break;
    case ActivityLog.EVENT_MEETING_STARTED:
      Component = MeetingStarted;
      break;
    case ActivityLog.EVENT_MEETING_ENDED:
      Component = MeetingEnded;
      break;
    case ActivityLog.EVENT_MEETING_PARTICIPANT_JOINED:
      Component = MeetingParticipantJoined;
      break;
    case ActivityLog.EVENT_MEETING_PARTICIPANT_LEFT:
      Component = MeetingParticipantLeft;
      break;


    /***** ORDER VEHICLE *****/

    case ActivityLog.EVENT_ORDER_VEHICLE_CREATED:
      Component = OrderVehicleCreated;
      break;
    case ActivityLog.EVENT_ORDER_VEHICLE_UPDATED:
      Component = OrderVehicleUpdated;
      break;
    case ActivityLog.EVENT_ORDER_VEHICLE_DELETED:
      Component = OrderVehicleDeleted;
      break;


    /***** ORDER NOTARY *****/

    case ActivityLog.EVENT_ORDER_NOTARY_ASSIGNED:
      Component = OrderNotaryAssigned;
      break;
    case ActivityLog.EVENT_ORDER_NOTARY_DOCS_RECEIVED:
      Component = OrderNotaryDocsReceived;
      break;
    case ActivityLog.EVENT_ORDER_NOTARY_APPOINTMENT_SET:
      Component = OrderNotaryAppointmentSet;
      break;
    case ActivityLog.EVENT_ORDER_NOTARY_APPOINTMENT_ACCEPTED:
      Component = OrderNotaryAppointmentAccepted;
      break;
    case ActivityLog.EVENT_ORDER_NOTARY_APPOINTMENT_DECLINED:
      Component = OrderNotaryAppointmentDeclined;
      break;
    case ActivityLog.EVENT_ORDER_NOTARY_DOCS_PRINTED:
      Component = OrderNotaryDocsPrinted;
      break;
    case ActivityLog.EVENT_ORDER_NOTARY_DOCS_UPLOADED:
      Component = OrderNotaryDocsUploaded;
      break;
    case ActivityLog.EVENT_ORDER_NOTARY_ORDER_COMPLETED:
      Component = OrderNotaryOrderCompleted;
      break;
    case ActivityLog.EVENT_ORDER_NOTARY_DELETED:
      Component = OrderNotaryDeleted;
      break;


    /***** PAYMENT *****/

    case ActivityLog.EVENT_PAYMENT_REQUESTED:
      Component = PaymentRequested;
      break;
    case ActivityLog.EVENT_PAYMENT_FAILED:
      Component = PaymentFailed;
      break;
    case ActivityLog.EVENT_PAYMENT_COMPLETED:
      Component = PaymentCompleted;
      break;


    /***** NOTARY *****/

    case ActivityLog.EVENT_NOTARY_SMS_OPT_IN:
      Component = NotarySmsOptIn;
      break;
    case ActivityLog.EVENT_NOTARY_SMS_OPT_OUT:
      Component = NotarySmsOptOut;
      break;


    /***** USER *****/

    case ActivityLog.EVENT_USER_NOTARY_BID_ACCEPTED_MAIL_SENT:
      Component = UserNotaryBidAcceptedMailSent;
      break;
    case ActivityLog.EVENT_USER_NOTARY_APPOINTMENT_DECLINED_MAIL_SENT:
      Component = UserNotaryAppointmentDeclinedMailSent;
      break;
    case ActivityLog.EVENT_USER_SCHEDULER_ASSIGNED_TO_ORDER_MAIL_SENT:
      Component = UserSchedulerAssignedToOrderMailSent;
      break;
    case ActivityLog.EVENT_USER_NOTARY_APPOINTMENT_SET_MAIL_SENT:
      Component = UserNotaryAppointmentSetMailSent;
      break;
    case ActivityLog.EVENT_USER_MEETING_CREATED_MAIL_SENT:
      Component = UserMeetingCreatedMailSent;
      break;
    case ActivityLog.EVENT_USER_NOTARY_DOCS_RECEIVED_MAIL_SENT:
      Component = UserNotaryDocsReceivedMailSent;
      break;
    case ActivityLog.EVENT_USER_NOTARY_BID_AWARDED_MAIL_SENT:
      Component = UserNotaryBidAwardedMailSent;
      break;
    case ActivityLog.EVENT_USER_NOTARY_BID_AWARDED_SMS_SENT:
      Component = UserNotaryBidAwardedSmsSent;
      break;
    case ActivityLog.EVENT_USER_NOTARY_MUST_UPLOAD_DOCS_MAIL_SENT:
      Component = UserNotaryMustUploadDocsMailSent;
      break;
    case ActivityLog.EVENT_USER_NOTARY_START_ORDER_MAIL_SENT:
      Component = UserNotaryStartOrderMailSent;
      break;
    case ActivityLog.EVENT_USER_ORDER_COMPLETED_BY_NOTARY_MAIL_SENT:
      Component = UserOrderCompletedByNotaryMailSent;
      break;
    case ActivityLog.EVENT_USER_NOTARY_APPOINTMENT_ACCEPTED_MAIL_SENT:
      Component = UserNotaryAppointmentAcceptedMailSent;
      break;
    case ActivityLog.EVENT_USER_NEW_NOTARY_BID_MAIL_SENT:
      Component = UserNewNotaryBidMailSent;
      break;
    case ActivityLog.EVENT_USER_NOTARY_BID_EXPIRED_MAIL_SENT:
      Component = UserNotaryBidExpiredMailSent;
      break;
    case ActivityLog.EVENT_USER_NOTARY_BID_REJECTED_MAIL_SENT:
      Component = UserNotaryBidRejectedMailSent;
      break;
    case ActivityLog.EVENT_USER_TRACKING_INFO_ADDED_MAIL_SENT:
      Component = UserTrackingInfoAddedMailSent;
      break;
    case ActivityLog.EVENT_USER_TRACKING_INFO_ADDED_SMS_SENT:
      Component = UserTrackingInfoAddedSmsSent;
      break;
    case ActivityLog.EVENT_USER_NEW_NOTARY_BID_SMS_SENT:
      Component = UserNewNotaryBidSmsSent;
      break;
    case ActivityLog.EVENT_USER_SMS_CONSENT_SENT:
      Component = UserSmsConsentSent;
      break;
    case ActivityLog.EVENT_USER_DOC_REJECTED_MAIL_SENT:
      Component = UserDocRejectedMailSent;
      break;
    case ActivityLog.EVENT_USER_DOC_REJECTED_SMS_SENT:
      Component = UserDocRejectedSmsSent;
      break;
    case ActivityLog.EVENT_USER_PAGE_REJECTED_MAIL_SENT:
      Component = UserPageRejectedMailSent;
      break;
    case ActivityLog.EVENT_USER_PAGE_REJECTED_SMS_SENT:
      Component = UserPageRejectedSmsSent;
      break;
    case ActivityLog.EVENT_USER_NOTARY_APPOINTMENT_ACCEPTED_SMS_SENT:
      Component = UserNotaryAppointmentAcceptedSmsSent;
      break;
    case ActivityLog.EVENT_USER_NOTARY_APPOINTMENT_DECLINED_SMS_SENT:
      Component = UserNotaryAppointmentDeclinedSmsSent;
      break;
    case ActivityLog.EVENT_USER_NOTARY_BID_DECLINED_MAIL_SENT:
      Component = UserNotaryBidDeclinedMailSent;
      break;
    case ActivityLog.EVENT_USER_NOTARY_BID_REJECTED_SMS_SENT:
      Component = UserNotaryBidRejectedSmsSent;
      break;


    /***** MUTED CHAT *****/

    case ActivityLog.EVENT_MUTED_CHAT_ALL_MUTED:
      Component = MutedChatAllMuted;
      break;
    case ActivityLog.EVENT_MUTED_CHAT_ALL_UNMUTED:
      Component = MutedChatAllUnmuted;
      break;
    case ActivityLog.EVENT_MUTED_CHAT_CHANNEL_MUTED:
      Component = MutedChatChannelMuted;
      break;
    case ActivityLog.EVENT_MUTED_CHAT_CHANNEL_UNMUTED:
      Component = MutedChatChannelUnmuted;
      break;


    /*****  DEALER STORE  *****/

    case ActivityLog.EVENT_DEALER_STORE_CREATED:
      Component = DealerStoreCreated;
      break;
    case ActivityLog.EVENT_DEALER_STORE_DELETED:
      Component = DealerStoreDeleted;
      break;
    case ActivityLog.EVENT_DEALER_STORE_INFO_UPDATED:
      Component = DealerStoreInfoUpdated;
      break;
    case ActivityLog.EVENT_DEALER_STORE_CONTACT_UPDATED:
      Component = DealerStoreContactUpdated;
      break;
    case ActivityLog.EVENT_DEALER_STORE_CONTACT2_UPDATED:
      Component = DealerStoreContact2Updated;
      break;
    case ActivityLog.EVENT_DEALER_STORE_FINANCE_UPDATED:
      Component = DealerStoreFinanceUpdated;
      break;
    case ActivityLog.EVENT_DEALER_STORE_BILLING_UPDATED:
      Component = DealerStoreBillingUpdated;
      break;
    case ActivityLog.EVENT_DEALER_STORE_PAYMENT_PLAN_CHANGED:
      Component = DealerStorePaymentPlanChanged;
      break;
    case ActivityLog.EVENT_DEALER_STORE_SUBSCRIPTION_CANCELLED:
      Component = DealerStoreSubscriptionCancelled;
      break;
    case ActivityLog.EVENT_DEALER_STORE_MONTHLY_USAGE_REPORT_SENT:
      Component = DealerStoreMonthlyUsageReportSent;
      break;


    /*****  DEALER STORE PAYMENT SETUP *****/

    case ActivityLog.EVENT_DEALER_STORE_PAYMENT_SETUP_CREATED:
      Component = DealerStorePaymentSetupCreated;
      break;
    case ActivityLog.EVENT_DEALER_STORE_PAYMENT_SETUP_DELETED:
      Component = DealerStorePaymentSetupDeleted;
      break;
    case ActivityLog.EVENT_DEALER_STORE_PAYMENT_SETUP_FINISHED:
      Component = DealerStorePaymentSetupFinished;
      break;


    /***** ORDER SIGNER VERIFICATION *****/

    case ActivityLog.EVENT_ORDER_SIGNER_VERIFICATION_CREATED:
      Component = OrderSignerVerificationCreated;
      break;
    case ActivityLog.EVENT_ORDER_SIGNER_VERIFICATION_CHECKED:
      Component = OrderSignerVerificationChecked;
      break;


    /***** ORDER FEE *****/

    case ActivityLog.EVENT_ORDER_FEE_CREATED:
      Component = OrderFeeCreated;
      break;
    case ActivityLog.EVENT_ORDER_FEE_UPDATED:
      Component = OrderFeeUpdated;
      break;
    case ActivityLog.EVENT_ORDER_FEE_DELETED:
      Component = OrderFeeDeleted;
      break;

    default:
      Component = NotImplemented;
      break;
  }

  return <li className="event-list" data-id={log._id}>
    <div className="event-timeline-dot" title={log.event}>
      <i className="bx bx-right-arrow-circle font-size-18"></i>
    </div>
    <Component {...props} />
  </li>
}

ActivityEntry.propTypes = {
  log: PropTypes.object,
}

export default ActivityEntry;