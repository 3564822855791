import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useSelector } from "react-redux";
import { dateFromTimestamp, getMonthName, getMonthsInRange } from "helpers/dateHelper";
import moment from "moment";
import downloadIcon from "../../../../assets/images/download-icon.svg";
import { getBeUrl } from "../../../../helpers/utilHelper";

const rows = [{
  id: "orders",
  name: "Orders with schedulers"
}, {
  id: "rushOrders",
  name: "Rush orders with schedulers"
}, {
  id: "cancelledOrders",
  name: "Canceled orders with schedulers"
}, {
  id: "cancelledOrdersWithNotary",
  name: "Worked but canceled orders with schedulers"
}, {
  id: "cancelledRushOrders",
  name: "Canceled rush orders with schedulers"
}, {
  id: "cancelledRushOrdersWithNotary",
  name: "Worked but canceled rush orders with schedulers"
}, {
  id: "avgOrdersPerSchedulerPerMonth",
  name: "Average number of orders per scheduler"
}, {
  id: "avgOrdersPerDayPerMonth",
  name: "Average number of orders per day"
}, {
  id: "businessDaysPerMonth",
  name: "Number of Workdays"
}]

const StatisticsReport = () => {

  /********** STATE **********/
  const { stats, isLoadInProgress } = useSelector(state => state.Report.Scheduler);
  const filters = useSelector(state => state.Report.SchedulerFilters);
  // check if the start date and end date selected are in the same year
  // this is needed, because if the data spans over multiple year, we will display the year as well in the column headers
  const isSameYear = moment(dateFromTimestamp(filters.startTs)).year() === moment(dateFromTimestamp(filters.endTs)).year();
  // when stats are loaded, build an array of the months included in the selected range
  // in order to render them as columns
  // the months are formatted as "YYYY-M"
  const monthsArray = !!Object.keys(stats)?.length ? getMonthsInRange(filters.startTs, filters.endTs) : [];
  // check if both start and end dates are set
  const hasTs = filters.startTs && filters.endTs;

  return (
    <React.Fragment>
      {!isLoadInProgress && !!rows?.length && (
        <>
          <div className="table-rep-plugin">
            <div
              className="table-responsive mb-0"
              data-pattern="priority-columns"
            >
              <Table className="table report-table mb-0 bb-0">
                <Thead className="report-header">
                  <Tr>
                    <Th>Statistics</Th>
                    {monthsArray.map(month => (<Th key={month}>{getMonthName(month, isSameYear)}</Th>))}
                    <Th>Total</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {rows.map((entry) => (
                    <Tr key={entry.id}>
                      <Td className="report-cell name-cell">{entry.name}</Td>
                      {monthsArray.map(month => (<Td key={month} className="report-cell">{stats[entry.id][month] || "--"}</Td>))}
                      <Td className="report-cell">
                        {stats[entry.id]?.total === 0
                          ? "--"
                          : Number.isInteger(stats[entry.id]?.total)
                            ? stats[entry.id]?.total
                            : stats[entry.id]?.total.toFixed(2)}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </div>
          <div className='d-flex justify-content-end mt-4 mb-4'>
            <a className={`btn btn-primary ${hasTs ? '' : 'disabled'}`} href={getBeUrl(`/report/scheduler-performance/download?startTs=${filters.startTs}&endTs=${filters.endTs}`)} target="_blank" rel="noreferrer">
              <span>Download</span>
              <img className='ps-2' src={downloadIcon} />
            </a>
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default StatisticsReport;