import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import SpinnerChase from "components/Shared/SpinnerChase";
import { Alert } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { getPaymentPlanFees } from "../../../helpers/backendHelper";
import { perms, useAccess } from "../../../context/access";

const DataTableCustomFee = () => {

  let { id } = useParams();
  id = parseInt(id);

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  /********** STATE **********/

  const [paymentPlanFees, setPaymentPlanFees] = useState(null);
  const [paymentPlanFeesError, setPaymentPlanFeesError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  const isFeeAdd = paymentPlanFees?.length;

  /********** EFFECT **********/
  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the user data
    refreshPaymentPlanFees();
  }, [id]);

  /********** OTHER **********/
  const refreshPaymentPlanFees = () => {
    setIsLoadInProgress(true);
    getPaymentPlanFees(id)
      .then(response => {
        setPaymentPlanFees(response.fees)
      })
      .catch(err => {
        setPaymentPlanFeesError(err);
      })
      .finally(() => {
        setIsLoadInProgress(false)
      })
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="table-rep-plugin">
            <div
              className="table-responsive mb-0"
              data-pattern="priority-columns"
            >
              <Table className="table new-uploaded-docs mb-0 bb-0">
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th data-priority="1">Name</Th>
                    <Th data-priority="2">Price</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {isLoadInProgress && <Tr>
                    <Td colSpan="8">
                      <SpinnerChase className="sm mt-2 mb-2" />
                    </Td>
                  </Tr>}
                  {!!paymentPlanFeesError && <Tr>
                    <Td colSpan="8">
                      <Alert color="danger" className="fade show text-center mb-0">
                        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load fees
                      </Alert>
                    </Td>
                  </Tr>}
                  {isFeeAdd ? (
                    paymentPlanFees?.map((fee, index) => <Tr key={fee.id}>
                      <Th className="co-name">{index + 1}</Th>
                      {iAmGranted(perms.edit_payment_plans) ? (<Td><Link to={route(routes.view_payment_plan_fee, fee.id)}>{fee.additionalFeeName}</Link></Td>) :
                        (<Td>{fee.additionalFeeName}</Td>)
                      }
                      <Td>{fee.isPercentage ? fee.price + '%' : '$' + fee.price}</Td>
                    </Tr>)) : <Tr><Th className="border-0 font-size-13">No custom fee added.</Th></Tr>}
                </Tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default DataTableCustomFee;
