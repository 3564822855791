import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import SpinnerChase from "components/Shared/SpinnerChase";
import { Card, CardBody, Row, Col, Alert, Button, UncontrolledTooltip, Input } from "reactstrap";
import Confirmation from "components/Shared/Confirmation";
import { formatTimestamp, formats } from "helpers/dateHelper";
import { getDtFooterRowCount, getSharedTableOptions, showError, showSuccess } from "helpers/utilHelper";
import { applyPaymentDtFilters, doPaymentDtCleanup, getPaymentDt } from "store/actions";
import { getSharedPaginationOptions } from "helpers/utilHelper";
import Filters from "./Filters";
import { omit } from "lodash";
import config from "config";
import { Link } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import DealerStore from "model/dealerStore";
import DateRangePicker from "components/Shared/DateRangePicker";
import Payment from "model/payment";
import { retryPayment } from "helpers/backendHelper";
import { perms, useAccess } from "context/access";
import { useDebounce } from "hooks/debounce";

const DataTable = () => {

  const dispatch = useDispatch();
  const { iAmGranted } = useAccess();

  /********** STATE **********/

  // get redux state from the store
  const { payments: rows, paymentsError: rowsError, totalCount, listParams, isLoadInProgress } = useSelector(state => state.Payment.Dt);
  const filters = useSelector(state => state.Payment.DtFilters);
  const [searchTerm, setSearchTerm] = useState(listParams.search);

  // Debounce the value of the search
  const debouncedSearch = useDebounce(searchTerm, config.DATA_TABLE_SEARCH_DELAY);

  const [range, setRange] = useState([]);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [paymentToRetry, setPaymentToRetry] = useState();

  // datatable PaginationProvider options
  const [paginationOptions, setPaginationOptions] = useState({
    ...getSharedPaginationOptions(),
    totalSize: totalCount,
    page: listParams.page,
    sizePerPage: listParams.pageSize,
    defaultSorted: [{
      dataField: listParams.sortBy,
      order: listParams.sortDir,
    }],
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // we do not get the list data here
    // instead we listen for changes on "filters" state var and do it there (see below)
    // this is to avoid fetching the data twice (both on component mount and on filters changed)
    return () => {
      // state cleanup on component unmount
      dispatch(doPaymentDtCleanup());
    }
  }, []);

  // runs whenever "totalCount" changes
  // which happens after the first remote call
  useEffect(() => {
    // now we know the total number of rows so let"s update the pagination
    setPaginationOptions(options => ({
      ...options,
      totalSize: totalCount,
    }));
  }, [totalCount]);

  // runs whenever "filters" changes
  // which happens after "apply-filters" or "clear-filters"
  // but also on component mount
  useEffect(() => {
    // "_set" is a special flag we use to know if the default filter values have been initialized (ex. from url)
    if (filters.hasOwnProperty("_set") && !filters._set) {
      return;
    }
    // refresh the list data based on the new filters
    dispatch(getPaymentDt({
      ...listParams,
      // "_set" is a special flag we use to know if the default filter values have been initialized
      // we do not want that passed to back-end
      filters: omit(filters, "_set"),
      // reset the page number when filtering
      // otherwise the current page number might be higher than the total number of pages after the filtering
      page: 1,
    }));
    // update the pagination with the new page number
    setPaginationOptions(options => ({
      ...options,
      page: 1,
    }));
  }, [filters]);

  // search is done manually, due to debouncing
  useEffect(() => {
    // '_set' is a special flag we use to know if the default filter values have been initialized (ex. from url)
    if (filters.hasOwnProperty('_set') && !filters._set) {
      return;
    }
    // reset the page number when searching
    // otherwise the current page number might be higher than the total number of pages after the search
    dispatch(getPaymentDt({
      ...listParams,
      page: 1,
      search: searchTerm,
    }));
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: 1,
    }));
  }, [debouncedSearch]);

  /********** EVENT HANDLERS **********/

  const applyFilters = values => dispatch(applyPaymentDtFilters(values));

  // runs whenever table params change (sorting, pagination, etc)
  const handleTableChange = (type, newState) => {
    // "_set" is a special flag we use to know if the default filter values have been initialized (ex. from url)
    // we want to ingore this event before the filters are initialized
    // therefore avoiding a duplicate call to the backend
    if (filters.hasOwnProperty("_set") && !filters._set) {
      return;
    }
    // refresh the list data based on the new table params
    dispatch(getPaymentDt({
      ...listParams,
      sortBy: newState.sortField,
      sortDir: newState.sortOrder,
      pageSize: newState.sizePerPage,
      page: newState.page,
      filters: omit(filters, "_set"),
    }));
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: newState.page,
      sizePerPage: newState.sizePerPage,
    }));
  };

  const handleChangeRange = selected => {
    // update local state
    setRange(selected);
    // if selection is complete, apply filters
    if (selected.length === 2) {
      applyFilters({ ...filters, createdBetween: selected.join("-") });
    }
  };

  const handleClearRange = () => {
    // if previous range was complete (both start and end dates selected)
    if (range.length === 2) {
      applyFilters({ ...filters, createdBetween: "" });
    }
    // update local state
    setRange([]);
  };

  const handleRetryPayment = id => () => {
    setIsConfirmationVisible(true);
    setPaymentToRetry(id);
  };

  const retry = () => {
    retryPayment(paymentToRetry)
      .then(() => {
        showSuccess("Payment has been retried");
        refreshPayments();
      })
      .catch(() => {
        showError("Cannot retry payment");
      })
  };

  const refreshPayments = () => {
    dispatch(getPaymentDt({
      ...listParams,
      // "_set" is a special flag we use to know if the default filter values have been initialized
      // we do not want that passed to back-end
      filters: omit(filters, "_set"),
    }));
  };

  return <React.Fragment>
    <Card className="paginated-table-card">
      <CardBody className="pt-3">
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              columns={getColumns(iAmGranted(perms.retry_order_payments), handleRetryPayment)}
              data={rows}>
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col>
                      <div className="search-box d-inline-block">
                        <div className="position-relative">
                          <Input type="text" onChange={e => setSearchTerm(e.target.value)} placeholder="Search" value={searchTerm} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                      <div className="d-inline-block ms-4">
                        <DateRangePicker
                          onChange={handleChangeRange}
                          value={range}
                          onClear={handleClearRange}
                        />
                      </div>
                    </Col>
                    <Col sm="auto">
                      <div className="text-end">
                        <Filters />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...getSharedTableOptions()}
                          noDataIndication={!rowsError && "No payments found"}
                          onTableChange={handleTableChange}
                          defaultSorted={paginationProps.defaultSorted}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                      {isLoadInProgress && <SpinnerChase className="sm dtable" />}
                      {!!rowsError && <Alert color="danger" className="fade show text-center">
                        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load payments
                      </Alert>}
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-3">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone
                          {...paginationProps}
                        />
                        <div className="d-inline ms-2">{getDtFooterRowCount(paginationProps, rows.length, totalCount)}</div>
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
    {isConfirmationVisible && <Confirmation
      reverseButtons={false}
      confirmBtnText="Retry"
      onConfirm={() => {
        setIsConfirmationVisible(false)
        retry();
      }}
      onCancel={() => {
        setIsConfirmationVisible(false);
        setPaymentToRetry(null);
      }}>
      {`Make sure the card balance is enough and the details are up to date. After triggering the Payment retry, please give it a couple of minutes to be processed and the Payment status to be updated.`}
    </Confirmation>}
  </React.Fragment>
}

const getColumns = (iAmGrantedToRetry, onRetry) => ([
  {
    dataField: "id",
    text: "Payment ID",
    sort: false,
  }, {
    dataField: "type",
    text: "Payment Type",
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => {
      switch (cellContent) {
        case Payment.TYPE_MULTIPLE_ORDERS:
          return "Collective";
        case Payment.TYPE_SINGLE_ORDER:
          return !!row.orderId ? <Link to={route(routes.view_order, row.orderId)}>Order {row.orderId}</Link> : "--"
        case Payment.TYPE_SUBSCRIPTION:
          return row.paymentPlanName;
        default:
          return "--"
      }
    },
  }, {
    dataField: "statementId",
    text: "Statement ID",
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: cellContent => cellContent ? <Link to={route(routes.view_dealer_statement, cellContent)}>{cellContent}</Link> : "--",
  }, {
    dataField: "paymentMethod",
    text: "Payment Method",
    sort: false,
    formatter: cellContent => DealerStore.getPaymentMethodName(cellContent),
  }, {
    dataField: "updatedTs",
    text: "Payment Date",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => (row.status === Payment.STATUS_FAILED || row.status === Payment.STATUS_COMPLETED) ? formatTimestamp(cellContent, formats.DATETIME) : "--",
  }, {
    dataField: "amount",
    text: "Total",
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => <span>${cellContent}</span>,
  }, {
    dataField: "dealerStoreName",
    text: "Dealership",
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => <Link to={route(routes.view_dealer_store, row.dealerStoreId)}>{cellContent}</Link>,
  }, {
    dataField: "status",
    text: "Status",
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => {
      const color = Payment.getStatusColor(cellContent);
      const canRetryPayment = iAmGrantedToRetry && row.status === Payment.STATUS_FAILED && DealerStore.isStripePaymentMethod(row.paymentMethod) && row.type === Payment.TYPE_SINGLE_ORDER;
      return <>
        <p className={`badge badge-lg rounded-pill bg-${color}`}>
          {Payment.getStatusName(cellContent)}
          {!!row.failReason && <React.Fragment>
            <a href={`https://docs.stripe.com/error-codes#${row.failReason.replaceAll('_', '-')}`} target="blank" id={`status-badge-${row.id}`}>
              <i className="mdi mdi-information-outline text-white ms-2"></i>
            </a>
            <UncontrolledTooltip placement="top" target={`status-badge-${row.id}`}>{row.failReason}</UncontrolledTooltip>
          </React.Fragment>}
        </p>
        {canRetryPayment && <Button color="danger" outline className="btn-rounded btn-sm ms-2" onClick={onRetry(row.id)}>Retry <i className="bx bx-sync" /></Button>}
      </>;
    },
  }]);

export default DataTable;
