import { combineReducers } from "redux";
import {
  GET_SUPPORT_CASE_DT,
  GET_SUPPORT_CASE_DT_OK,
  GET_SUPPORT_CASE_DT_ERR,
  DO_SUPPORT_CASE_DT_CLEANUP,
  SET_SUPPORT_CASE_DT_ACTIVE_TAB,

  APPLY_SUPPORT_CASE_DT_FILTERS,
  CLEAR_SUPPORT_CASE_DT_FILTERS,
  PATCH_SUPPORT_CASE_DT_FILTERS,
  UNSET_SUPPORT_CASE_DT_FILTERS,

} from "./actionTypes";

/********** DATATABLE **********/

const defaultDtState = {
  supportCases: [],
  supportCasesError: null,
  totalCount: 0,
  listParams: {
    sortBy: 'updatedTs',
    sortDir: 'desc',
    pageSize: 50,
    page: 1,
    search: null,
    filters: null,
  },
  isLoadInProgress: false,
  activeTab: 'my',
};

const Dt = (state = defaultDtState, action) => {
  switch (action.type) {
    case GET_SUPPORT_CASE_DT:
      state = {
        ...state,
        listParams: action.payload.params,
        supportCasesError: null,
        isLoadInProgress: true,
      };
      break
    case GET_SUPPORT_CASE_DT_OK:
      state = {
        ...state,
        supportCases: action.payload.response.supportCases,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_SUPPORT_CASE_DT_ERR:
      state = {
        ...state,
        supportCases: [],
        totalCount: 0,
        supportCasesError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_SUPPORT_CASE_DT_CLEANUP:
      state = {
        ...state,
        supportCases: [],
        supportCasesError: null,
      };
      break
    case SET_SUPPORT_CASE_DT_ACTIVE_TAB:
      state = {
        ...state,
        activeTab: action.payload.tab,
      };
      break
  }
  return state;
}

/********** DATATABLE FILTERS **********/

const defaultDtFiltersState = {
  id: '',
  status: '',
  orderStatus: '',
  vidStatus: '',
  created: '',
  customerFullName: '',
  dealerFullName: '',
  orderId: '',
  customerPhone: '',
  customerLocation: '',
  assigneeFullName: '',
  incAlert: [],
  _set: false,
};

const DtFilters = (state = defaultDtFiltersState, action) => {
  switch (action.type) {
    case APPLY_SUPPORT_CASE_DT_FILTERS:
      state = {
        ...action.payload.filters,
        _set: true,
      };
      break
    case CLEAR_SUPPORT_CASE_DT_FILTERS:
      state = {
        ...defaultDtFiltersState,
        _set: true,
      };
      break
    case PATCH_SUPPORT_CASE_DT_FILTERS:
      state = {
        ...state,
        ...action.payload.filters,
        _set: true,
      };
      break
    case UNSET_SUPPORT_CASE_DT_FILTERS:
      state = {
        ...state,
        _set: false,
      };
      break
  }
  return state;
}

export default combineReducers({
  Dt,
  DtFilters,
});