import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { Container, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import Col from "components/Shared/Col";
import SectionInfo from "./Partial/Section/Info";
import SectionContact from "./Partial/Section/Contact";
import SectionContact2 from "./Partial/Section/Contact2";
import SectionFinance from "./Partial/Section/Finance";
import SectionBilling from "./Partial/Section/Billing";
import SectionUsers from "./Partial/Section/Users";
import SectionComments from "./Partial/Section/Comments";
import SectionSigners from "./Partial/Section/Signers";
import SectionSpecialInstructions from "./Partial/Section/SpecialInstructions";
import { getDealerStore } from "helpers/backendHelper";
import DealerGroup from "model/dealerGroup";
import SectionPayments from "./Partial/Section/Payments";
import SectionRouteOne from "./Partial/Section/RouteOne";

const View = () => {

  let { id } = useParams();
  id = parseInt(id);

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const [dealerStore, setDealerStore] = useState(null);
  const [dealerStoreError, setDealerStoreError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);


  const isGroupSpecialInstructionsAppliesTo = () => dealerStore?.dealerGroupSpecialInstructionsAppliesTo?.some(item => item == DealerGroup.SPECIAL_INTSTRUCTIONS_APPLY_TO_ADMIN);
  const showGroupSpecialInstructions = () => dealerStore?.dealerGroupSpecialInstructions && isGroupSpecialInstructionsAppliesTo();

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the store data
    refreshDealerStore();
  }, [id]);

  /********** OTHER **********/
  const refreshDealerStore = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getDealerStore(id)
      .then(response => {
        setDealerStore(response.dealerStore);
      })
      .catch(ex => {
        setDealerStoreError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };


  return <React.Fragment>
    {iAmGranted(perms.view_dealer_stores) && <div className="page-content">
      {dealerStore && <React.Fragment>
        <MetaTitle>{dealerStore.name} | Dealer stores</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(dealerStore)} />
          <Row>
            <Col xs="12" xxxl="8">
              <SectionInfo id={id} dealerStore={dealerStore} refreshHandler={refreshDealerStore} isLoadBusy={isLoadInProgress} />
            </Col>
            <Col xs="12" xl="6" xxxl="4">
              <SectionContact id={id} dealerStore={dealerStore} refreshHandler={refreshDealerStore} isLoadBusy={isLoadInProgress} />
            </Col>
            <Col xs="12" xl="6" xxxl="4">
              <SectionContact2 id={id} dealerStore={dealerStore} refreshHandler={refreshDealerStore} isLoadBusy={isLoadInProgress} />
            </Col>
            <Col xs="12" xl="6" xxxl="4">
              <SectionFinance id={id} dealerStore={dealerStore} refreshHandler={refreshDealerStore} isLoadBusy={isLoadInProgress} />
            </Col>
            <Col xs="12" xl="6" xxxl="4">
              <SectionBilling id={id} dealerStore={dealerStore} refreshHandler={refreshDealerStore} isLoadBusy={isLoadInProgress} />
            </Col>
            <Col xxxl="8">
              <SectionUsers id={id} />
            </Col>
            <Col xs="12" xxxl="4">
              <SectionComments id={id} dealerStore={dealerStore} refreshHandler={refreshDealerStore} isLoadBusy={isLoadInProgress} />
            </Col>
            <Col xxxl="8">
              <SectionSigners id={id} />
            </Col>
            <Col xs="12" xl="6" xxxl="4">
              <SectionRouteOne id={id} dealerStore={dealerStore} refreshHandler={refreshDealerStore} isLoadBusy={isLoadInProgress} />
            </Col>
            {iAmGranted(perms.view_payments) &&
              <Col xs="12">
                <SectionPayments id={id} />
              </Col>
            }
            {showGroupSpecialInstructions() && <Col xs="12" xxxl="4">
              <SectionSpecialInstructions dealerStore={dealerStore} refreshHandler={refreshDealerStore} isLoadBusy={isLoadInProgress} />
            </Col>}
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !dealerStore && <Preloader className="inner" />}
      {dealerStoreError && <Error error={dealerStoreError} title404="Dealer store not found" />}
    </div>}
    {iAmNotGranted(perms.view_dealer_stores) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = dealerStore => [{
  title: 'DEALER STORES',
  url: route(routes.list_dealer_stores),
}, {
  title: dealerStore.name,
}];

export default View;
