export default class NotaryGlba {

  static EMPLOYEES_SELF = 1;
  static EMPLOYEES_SELF_AND_OTHERS = 2;

  static SECURITY_TRAINING_ANUALLY = 1;
  static SECURITY_TRAINING_BI_ANUALLY = 2;
  static SECURITY_TRAINING_OTHER = 3;

  static CUSTOMER_INFO_STORAGE_TYPE_CLOUD = 1;
  static CUSTOMER_INFO_STORAGE_TYPE_HARD_DRIVE = 2;
  static CUSTOMER_INFO_STORAGE_TYPE_CABINET = 3;
  static CUSTOMER_INFO_STORAGE_TYPE_OTHER = 4;

  static CUSTOMER_INFO_DISPOSAL_YES = 1;
  static CUSTOMER_INFO_DISPOSAL_NO = 2;
  static CUSTOMER_INFO_DISPOSAL_OTHER = 3;

  static CUSTOMER_INFO_STORAGE_PERIOD_ONE_YEAR = 1;
  static CUSTOMER_INFO_STORAGE_PERIOD_LESS_THAN_TWO_YEARS = 2;
  static CUSTOMER_INFO_STORAGE_PERIOD_MORE_THAN_TWO_YEARS = 3;
  static CUSTOMER_INFO_STORAGE_PERIOD_OTHER = 4;

  static getSecurityFrequently = value =>  {
    if(value === NotaryGlba.SECURITY_TRAINING_ANUALLY) return 'Annually';

    if(value === NotaryGlba.SECURITY_TRAINING_BI_ANUALLY) return 'Bi-Annually';
  };

  static getStorageTypes () {
    return {
      [this.CUSTOMER_INFO_STORAGE_TYPE_CLOUD]: 'Cloud service or 3rd party application',
      [this.CUSTOMER_INFO_STORAGE_TYPE_HARD_DRIVE]: 'Computer and phone hard drive',
      [this.CUSTOMER_INFO_STORAGE_TYPE_CABINET]: 'Physical locked storage cabinet',
    }
  }

  static getStorageTypeName(type) {
    return this.getStorageTypes()[type]
  }

  static getStoragePeriod = (value) => {
    if(value === NotaryGlba.CUSTOMER_INFO_STORAGE_PERIOD_ONE_YEAR) return 'Less than one year';

    if(value === NotaryGlba.CUSTOMER_INFO_STORAGE_PERIOD_LESS_THAN_TWO_YEARS) return 'Less than two years';

    if(value === NotaryGlba.CUSTOMER_INFO_STORAGE_PERIOD_MORE_THAN_TWO_YEARS) return 'More than two years';
  }
}