import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import SpinnerChase from "components/Shared/SpinnerChase";
import { Link, } from "react-router-dom"
import { Card, CardBody, Row, Col, Alert, Input } from "reactstrap"
import { getDtFooterRowCount, getSharedTableOptions } from "helpers/utilHelper"
import { perms, useAccess } from 'context/access';
import { doDealerStoreDtCleanup, getDealerStoreDt } from "store/actions";
import { getSharedPaginationOptions } from "helpers/utilHelper";
import { route, routes } from "helpers/routeHelper";
import Filters from './Filters';
import config from 'config';
import UsStates from "constants/usState";
import { useDebounce } from 'hooks/debounce';

const DataTable = () => {

  const dispatch = useDispatch();
  const { iAmGranted } = useAccess();

  /********** STATE **********/

  const { dealerStores: rows, dealerStoresError: rowsError, totalCount, listParams, isLoadInProgress } = useSelector(state => state.DealerStore.Dt);
  const filters = useSelector(state => state.DealerStore.DtFilters);
  const [searchTerm, setSearchTerm] = useState(listParams.search);

  // Debounce the value of the search
  const debouncedSearch = useDebounce(searchTerm, config.DATA_TABLE_SEARCH_DELAY);

  // datatable PaginationProvider options
  const [paginationOptions, setPaginationOptions] = useState({
    ...getSharedPaginationOptions(),
    totalSize: totalCount,
    page: listParams.page,
    sizePerPage: listParams.pageSize,
    defaultSorted: [{
      dataField: listParams.sortBy,
      order: listParams.sortDir,
    }],
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // we do not get the list data here
    // instead we listen for changes on 'filters' state var and do it there (see below)
    // this is to avoid fetching the data twice (both on component mount and on filters changed)
    return () => {
      // state cleanup on component unmount
      dispatch(doDealerStoreDtCleanup());
    }
  }, []);

  // runs whenever 'totalCount' changes
  // which happens after the first remote call
  useEffect(() => {
    // now we know the total number of rows so let's update the pagination
    setPaginationOptions(options => ({
      ...options,
      totalSize: totalCount,
    }));
  }, [totalCount]);

  // runs whenever 'filters' changes
  // which happens after 'apply-filters' or 'clear-filters'
  // but also on component mount
  useEffect(() => {
    // refresh the list data based on the new filters
    dispatch(getDealerStoreDt({
      ...listParams,
      filters: filters,
      // reset the page number when filtering
      // otherwise the current page number might be higher than the total number of pages after the filtering
      page: 1,
    }));
    // update the pagination with the new page number
    setPaginationOptions(options => ({
      ...options,
      page: 1,
    }));
  }, [filters]);

  // search is done manually, due to debouncing
  useEffect(() => {
    // '_set' is a special flag we use to know if the default filter values have been initialized (ex. from url)
    if (filters.hasOwnProperty('_set') && !filters._set) {
      return;
    }
    // reset the page number when searching
    // otherwise the current page number might be higher than the total number of pages after the search
    dispatch(getDealerStoreDt({
      ...listParams,
      filters: filters,
      page: 1,
      search: searchTerm,
    }));
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: 1,
    }));
  }, [debouncedSearch]);

  /********** EVENT HANDLERS **********/

  // runs whenever table params change (sorting, pagination, etc)
  const handleTableChange = (type, newState) => {
    // refresh the list data based on the new table params
    dispatch(getDealerStoreDt({
      ...listParams,
      sortBy: newState.sortField,
      sortDir: newState.sortOrder,
      pageSize: newState.sizePerPage,
      page: newState.page,
    }));
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: newState.page,
      sizePerPage: newState.sizePerPage,
    }));
  };

  return <React.Fragment>
    <Card className="paginated-table-card">
      <CardBody className="pt-3">
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField='id'
              columns={columns}
              data={rows}>
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col>
                      <div className="search-box d-inline-block">
                        <div className="position-relative">
                          <Input type="text" onChange={e => setSearchTerm(e.target.value)} placeholder="Search" value={searchTerm} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="auto">
                      <div className="text-end">
                        {iAmGranted(perms.create_dealer_stores) && <Link to={route(routes.new_dealer_store)} className="btn btn-primary mb-2">
                          <i className="mdi mdi-plus me-1" />Add store
                        </Link>}
                        <Filters />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...getSharedTableOptions()}
                          noDataIndication={!rowsError && "No dealer stores found"}
                          onTableChange={handleTableChange}
                          defaultSorted={paginationProps.defaultSorted}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                      {isLoadInProgress && <SpinnerChase className="sm dtable" />}
                      {!!rowsError && <Alert color="danger" className="fade show text-center">
                        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load dealer stores
                      </Alert>}
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-3">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone
                          {...paginationProps}
                        />
                        <div className="d-inline ms-2">{getDtFooterRowCount(paginationProps, rows.length, totalCount)}</div>
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  </React.Fragment>
}

const columns = [{
  // this column is not used but we need it for the default sorting to work
  // if dataField does not match a defined column, it will be ignored
  dataField: 'id',
  sort: true,
  hidden: true,
}, {
  dataField: 'name',
  text: 'Name',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <Link to={route(routes.view_dealer_store, row.id)}>{cellContent}</Link>,
}, {
  dataField: 'state',
  text: 'State',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <span>{UsStates.find(option => option.id === row.state)?.name ?? '--'}</span>,
}, {
  dataField: 'dealerGroupName',
  text: 'Group',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => cellContent ? <Link to={route(routes.view_dealer_group, row.dealerGroupId)}>{cellContent}</Link> : '--',
}, {
  dataField: 'dealerRegionName',
  text: 'Region',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => cellContent ? <Link to={route(routes.view_dealer_region, row.dealerRegionId)}>{cellContent}</Link> : '--',
}, {
  dataField: 'qbCustomerId',
  text: 'QB Customer ID',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent) => cellContent || '--',
}];

export default DataTable;
