import React from "react"
import PropTypes from 'prop-types';

const UsageReportTableCell = ({ quantity, amount }) => {

  return <React.Fragment>
    <div className="usage-report-cell-parent">
      <div className="cell-qty">{quantity}</div>
      <div className="cell-amt">${parseInt(amount)}</div>
    </div>
  </React.Fragment>
}

UsageReportTableCell.propTypes = {
  quantity: PropTypes.number,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default UsageReportTableCell;
