import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, CardHeader, CardTitle } from "reactstrap";
import { formats, formatTimestampUtc } from 'helpers/dateHelper';
import { getOutOfOffice } from "helpers/backendHelper";
import { showError } from "helpers/utilHelper";

const SectionOutOfOffice = ({ notary }) => {

  /********** STATE **********/
  const[outOfOfficeDates, setOutOfOfficeDates] = useState([]);

  /********** HANDLERS **********/
  const getOutOfOfficeDates = () => {
    getOutOfOffice(notary.id)
      .then(resp => setOutOfOfficeDates(resp))
      .catch(err => showError("Unable to load out of office data."))
  }

  /********* EFFECTS *********/
  useEffect(() => {
    getOutOfOfficeDates();
  },[])

  return (<Card className="expand-v">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <CardTitle className="mb-0">Out of Office Dates</CardTitle>
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      {outOfOfficeDates.length === 0 ? (
        <p>No intervals added yet.</p>
      ) : (
        outOfOfficeDates.map((interval, index) => <div key={index} className="out-of-office-box mb-3">
          <div>{formatTimestampUtc(interval.startTs, formats.FULL_MONTH_DATE)} - {formatTimestampUtc(interval.endTs, formats.FULL_MONTH_DATE)}</div>
          {!!interval.reason && <div className="mt-3">
              <div className="font-weight-semibold mb-2">Reason</div>
              <div>{interval.reason}</div>
            </div>}
        </div>)
      )}
    </CardBody>
  </Card>)
}

SectionOutOfOffice.propTypes = {
  notary: PropTypes.object,
};

export default SectionOutOfOffice;