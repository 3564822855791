import { takeEvery, put, call } from "redux-saga/effects";

import {
  getSupportCaseDt,
} from "helpers/backendHelper";

import {
  getSupportCaseDtOk,
  getSupportCaseDtErr,
} from "./actions";

import {
  GET_SUPPORT_CASE_DT,
} from "./actionTypes";

/********** DATATABLE **********/

function* onGetSupportCaseDt({ payload: { params } }) {
  try {
    const { filters } = params;

    let { incAlert, ...rest } = filters;
    // parse arrays to strings
    const alert = incAlert.join(",");
    const response = yield call(getSupportCaseDt, { ...params, filters: { alert, ...rest } });
    yield put(getSupportCaseDtOk(response));
  } catch (error) {
    yield put(getSupportCaseDtErr(error));
  }
}

function* supportCaseSaga() {
  yield takeEvery(GET_SUPPORT_CASE_DT, onGetSupportCaseDt);
}

export default supportCaseSaga;