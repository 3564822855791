import React from "react";
import MetaTitle from "components/Shared/MetaTitle";
import { Container, Card, CardBody } from "reactstrap";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Breadcrumbs from "components/Common/Breadcrumb2";
import AccessDenied from "pages/Error/AccessDenied";
import { useSelector } from "react-redux";
import { perms, useAccess } from "context/access";
import DealerCountReport from "./Partial/Section/DealerCount";
import NewDealersFilters from "./Partial/NewDealersFilters";

const NewDealers = () => {

  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const filters = useSelector(state => state.Report.NewDealersFilters);

  // range is ready if both start and end dates have been selected
  const isRangeSet = !!(filters.startTs && filters.endTs);

  return (
    <React.Fragment>
      {iAmGranted(perms.view_new_dealers_reports) && (
        <div className="page-content">
          <MetaTitle>New Dealer Users</MetaTitle>
          <Container fluid>
            <Breadcrumbs title="NEW DEALER USERS" />
            <Card>
              <CardBody>
                <NewDealersFilters />
                {isRangeSet && <>
                  <DealerCountReport />
                </>}
              </CardBody>
            </Card>
          </Container>
        </div>
      )}
      {iAmNotGranted(perms.view_new_dealers_reports) && <AccessDenied />}
    </React.Fragment>
  )
}

export default NewDealers;