import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { Label, FormFeedback, Button, ButtonGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, UncontrolledTooltip } from "reactstrap";
import TextareaAutosize from "react-textarea-autosize";
import classnames from "classnames";
import Order from 'model/order';
import { useDispatch, useSelector } from 'react-redux';
import { showError, showSuccess } from 'helpers/utilHelper';
import { route, routes } from 'helpers/routeHelper';
import { deleteOrder, getFullOrder } from 'store/actions';
import Confirmation from 'components/Shared/Confirmation';
import cancelIcon from 'assets/images/red-bin-icon.svg';
import {
  cancelOrder,
  uncancelOrder,
  accountingReviewOrder,
  duplicateReviewOrder,
  underReviewOrder,
  onHoldReviewOrder
} from "helpers/backendHelper";
import { perms, useAccess } from "context/access";
import * as Yup from "yup";
import { FieldArray, FormikProvider, useFormik } from "formik";
import {
  ORDER_ACCOUNTING_STATUS_INVALID_FOR_UNCANCEL,
} from 'helpers/errorHelper';

const SectionOrderReviews = ({ order, id, refreshHandler }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { iAmGranted } = useAccess();

  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [cancelConfirmationVisible, setCancelConfirmationVisible] = useState(false);
  const [uncancelConfirmationVisible, setUncancelConfirmationVisible] = useState(false);

  // Review Modals

  const [accountingReviewConfirmationVisible, setAccountingReviewConfirmationVisible] = useState(false);
  const [accountingReviewStatus, setAccountingReviewStatus] = useState(null);
  const [accountingReviewDropdownOpen, setAccountingReviewDropdownOpen] = useState(false);
  const toggleAccountingReview = () => setAccountingReviewDropdownOpen((prevState) => !prevState);

  const [duplicateReviewConfirmationVisible, setDuplicateReviewConfirmationVisible] = useState(false);
  const [duplicateReviewStatus, setDuplicateReviewStatus] = useState(null);
  const [duplicateReviewDropdownOpen, setDuplicateReviewDropdownOpen] = useState(false);
  const toggleDuplicateReview = () => setDuplicateReviewDropdownOpen((prevState) => !prevState);

  const [underReviewConfirmationVisible, setUnderReviewConfirmationVisible] = useState(false);
  const [underReviewStatus, setUnderReviewStatus] = useState(null);
  const [underReviewDropdownOpen, setUnderReviewDropdownOpen] = useState(false);
  const toggleUnderReview = () => setUnderReviewDropdownOpen((prevState) => !prevState);

  const [onHoldReviewConfirmationVisible, setOnHoldReviewConfirmationVisible] = useState(false);
  const [onHoldReviewStatus, setOnHoldReviewStatus] = useState(null);
  const [onHoldReviewDropdownOpen, setOnHoldReviewDropdownOpen] = useState(false);
  const toggleOnHoldReview = () => setOnHoldReviewDropdownOpen((prevState) => !prevState);

  /******** CONSTANTS  ***********/

  const canUncancelOrder = () => isOrderCancelled() && order.accountingStatus == Order.ACCOUNTING_STATUS_NOT_SETTLED;

  const refreshOrder = useCallback(() => dispatch(getFullOrder(id)), [id]);

  const isOrderCancelled = () => order.status === Order.STATUS_CANCELLED;

  const isOrderDraft = () => order.status === Order.STATUS_DRAFT;

  const isOrderCompleted = () => order.status === Order.STATUS_COMPLETED;

  const canViewAccountingReview = () => iAmGranted(perms.add_order_accounting_review);
  const canActivateAccountingReview = () => iAmGranted(perms.add_order_accounting_review) && !order.accountingReviewStatus;
  const canResolveAccountingReview = () => iAmGranted(perms.resolve_order_accounting_review) && order.accountingReviewStatus == Order.REVIEW_STATUS_ACTIVE;
  const canIgnoreAccountingReview = () => iAmGranted(perms.resolve_order_accounting_review) && order.accountingReviewStatus;
  const canEditAccountingReview = () => canActivateAccountingReview() || canResolveAccountingReview() || canIgnoreAccountingReview();

  const canViewDuplicateReview = () => iAmGranted(perms.add_order_duplicate_review);
  const canActivateDuplicateReview = () => iAmGranted(perms.add_order_duplicate_review) && !order.duplicateReviewStatus;
  const canResolveDuplicateReview = () => iAmGranted(perms.resolve_order_duplicate_review) && order.duplicateReviewStatus == Order.REVIEW_STATUS_ACTIVE;
  const canIgnoreDuplicateReview = () => iAmGranted(perms.resolve_order_duplicate_review) && order.duplicateReviewStatus;
  const canEditDuplicateReview = () => canActivateDuplicateReview() || canResolveDuplicateReview() || canIgnoreDuplicateReview();

  const canViewUnderReview = () => iAmGranted(perms.add_order_under_review);
  const canActivateUnderReview = () => iAmGranted(perms.add_order_under_review) && !order.underReviewStatus;
  const canResolveUnderReview = () => iAmGranted(perms.resolve_order_under_review) && order.underReviewStatus == Order.REVIEW_STATUS_ACTIVE;
  const canIgnoreUnderReview = () => iAmGranted(perms.resolve_order_under_review) && order.underReviewStatus;
  const canEditUnderReview = () => canActivateUnderReview() || canResolveUnderReview() || canIgnoreUnderReview();


  const canViewOnHoldReview = () => iAmGranted(perms.add_order_on_hold_review);
  const canActivateOnHoldReview = () => iAmGranted(perms.add_order_on_hold_review) && !order.onHoldReviewStatus;
  const canResolveOnHoldReview = () => iAmGranted(perms.resolve_order_on_hold_review) && order.onHoldReviewStatus == Order.REVIEW_STATUS_ACTIVE;
  const canIgnoreOnHoldReview = () => iAmGranted(perms.resolve_order_on_hold_review) && order.onHoldReviewStatus;
  const canEditOnHoldReview = () => canActivateOnHoldReview() || canResolveOnHoldReview() || canIgnoreOnHoldReview();


  const { deleteInProgress, deleted } = useSelector(state => state.Order.Single);

  /********** FORM CONFIG **********/

  const formikAccountingReview = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      accountingReviewReason: ''
    },
    validationSchema: accountingReviewStatus != Order.REVIEW_STATUS_IGNORE ? Yup.object({
      accountingReviewReason: Yup.string().trim().required("Field is required"),
    }) : null,
    onSubmit: values => {
      addOrderAccountingReview(values);
    },
  });

  const formikDuplicateReview = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      duplicateReviewReason: ''
    },
    validationSchema: duplicateReviewStatus != Order.REVIEW_STATUS_IGNORE ? Yup.object({
      duplicateReviewReason: Yup.string().trim().required("Field is required"),
    }) : null,
    onSubmit: values => {
      addOrderDuplicateReview(values);
    },
  });

  const formikUnderReview = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      underReviewReason: ''
    },
    validationSchema: underReviewStatus != Order.REVIEW_STATUS_IGNORE ? Yup.object({
      underReviewReason: Yup.string().trim().required("Field is required"),
    }) : null,
    onSubmit: values => {
      addOrderUnderReview(values);
    },
  });

  const formikOnHoldReview = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      onHoldReviewReason: ''
    },
    validationSchema: onHoldReviewStatus != Order.REVIEW_STATUS_IGNORE ? Yup.object({
      onHoldReviewReason: Yup.string().trim().required("Field is required"),
    }) : null,
    onSubmit: values => {
      addOrderOnHoldReview(values);
    },
  });

  const formikCancellation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      cancellationReason: '',
      cancellationComment: '',
    },
    validationSchema: Yup.object({
      cancellationReason: Yup.number().required('The reason for cancellation is required, please select one.'),
    }),
    onSubmit: values => markOrderAsCancel(values),
  });

  /********** EFFECTS **********/

  useEffect(() => {
    if (deleted === true) {
      showSuccess("Order has been deleted");
      navigate(route(routes.list_orders));
    } else if (deleted === false) {
      showError("Unable to delete order");
    }
  }, [deleted, navigate]);

  useEffect(() => {
    if (accountingReviewStatus) {
      setAccountingReviewConfirmationVisible(true);
    }
  }, [accountingReviewStatus]);

  useEffect(() => {
    if (duplicateReviewStatus) {
      setDuplicateReviewConfirmationVisible(true);
    }
  }, [duplicateReviewStatus]);

  useEffect(() => {
    if (underReviewStatus) {
      setUnderReviewConfirmationVisible(true);
    }
  }, [underReviewStatus]);

  useEffect(() => {
    if (onHoldReviewStatus) {
      setOnHoldReviewConfirmationVisible(true);
    }
  }, [onHoldReviewStatus]);

  /*********** ORDER REVIEW ********/

  const addOrderAccountingReview = data => {
    accountingReviewOrder(order.id, { accountingReviewStatus, ...data })
      .then(() => {
        showSuccess("Order accounting review updated");
        formikAccountingReview.resetForm();
        refreshOrder();
      })
      .catch(() => {
        showError("Unable to review order accounting");
      })
    setAccountingReviewConfirmationVisible(false);
  };

  const addOrderDuplicateReview = data => {
    duplicateReviewOrder(order.id, { duplicateReviewStatus, ...data })
      .then(() => {
        showSuccess("Order duplicate review updated");
        formikDuplicateReview.resetForm();
        refreshOrder();
      })
      .catch(() => {
        showError("Unable to review order as duplicate");
      })
    setDuplicateReviewConfirmationVisible(false);
  };

  const addOrderUnderReview = data => {
    underReviewOrder(order.id, { underReviewStatus, ...data })
      .then(() => {
        showSuccess("Order under review updated");
        formikUnderReview.resetForm();
        refreshOrder();
      })
      .catch(() => {
        showError("Unable to review order");
      })
    setUnderReviewConfirmationVisible(false);
  };

  const addOrderOnHoldReview = data => {
    onHoldReviewOrder(order.id, { onHoldReviewStatus, ...data })
      .then(() => {
        showSuccess("Order on hold review updated");
        formikOnHoldReview.resetForm();
        refreshOrder();
      })
      .catch(() => {
        showError("Unable to mark order as on hold");
      })
    setOnHoldReviewConfirmationVisible(false);
  };

  /********* CANCEL/DELETE ORDER *********/

  const removeOrder = () => dispatch(deleteOrder(order.id));

  const handleUncancelOrder = () => {
    uncancelOrder(id)
      .then(() => {
        refreshOrder();
        showSuccess("Order have been uncancelled.");
        refreshHandler()
      })
      .catch(ex => {
        if (ex.code == ORDER_ACCOUNTING_STATUS_INVALID_FOR_UNCANCEL) {
          showError("Unable to uncancel order after fees have been settled");
          return;
        }
        showError("Unable to uncancel order.");
      })
  }

  const markOrderAsCancel = data => {
    cancelOrder(order.id, data)
      .then(() => {
        showSuccess("Order have been canceled.");
        formikCancellation.resetForm();
        refreshOrder();
      })
      .catch(() => {
        showError("Unable to cancel order.");
      })
    setCancelConfirmationVisible(false);
  }

  /************* FIELD FOCUS ***********/

  const onFieldFocusedAccountingReview = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formikAccountingReview.errors;
    delete errors[name];
    formikAccountingReview.setStatus(errors);
  }

  const onFieldFocusedDuplicateReview = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formikDuplicateReview.errors;
    delete errors[name];
    formikDuplicateReview.setStatus(errors);
  }

  const onFieldFocusedUnderReview = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formikUnderReview.errors;
    delete errors[name];
    formikUnderReview.setStatus(errors);
  }

  const onFieldFocusedOnHoldReview = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formikOnHoldReview.errors;
    delete errors[name];
    formikOnHoldReview.setStatus(errors);
  }

  const onFieldFocusedCancellation = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formikCancellation.errors;
    delete errors[name];
    formikCancellation.setStatus(errors);
  }

  return <React.Fragment>

    <div className="d-flex align-items-center justify-content-end flex-grow-1">
      {/* Accounting Review */}
      {canViewAccountingReview() &&
        <Dropdown className="d-inline-block accounting-review-dropdown review-action-buttons pe-2" isOpen={accountingReviewDropdownOpen} toggle={toggleAccountingReview} disabled={!canEditAccountingReview()}>
          <DropdownToggle id="accounting-hold" caret color={Order.getReviewStatusColor(order.accountingReviewStatus)} className="d-flex align-items-center">
            <i className="bx bx-calculator bx-xs" />
            <span className='d-inline me-3 ms-2'>{order.accountingReviewStatus ? Order.getReviewStatusName(order.accountingReviewStatus) : 'Accounting Hold'}</span>
            {canEditAccountingReview() && <i className="fas fa-caret-down" />}
          </DropdownToggle>
          {order.accountingReviewStatus && <UncontrolledTooltip placement="top" target="accounting-hold">Accounting Hold</UncontrolledTooltip>}
          <DropdownMenu className="accounting-review-dropdown">
            {canActivateAccountingReview() && <DropdownItem onClick={() => { setAccountingReviewStatus(Order.REVIEW_STATUS_ACTIVE) }}>Activate</DropdownItem>}
            {canIgnoreAccountingReview() && <DropdownItem onClick={() => { setAccountingReviewStatus(Order.REVIEW_STATUS_IGNORE) }}>Ignore</DropdownItem>}
          </DropdownMenu>
        </Dropdown>}

      {/* Duplicate Review */}
      {canViewDuplicateReview() &&
        <Dropdown className="d-inline-block duplicate-review-dropdown review-action-buttons pe-2" isOpen={duplicateReviewDropdownOpen} toggle={toggleDuplicateReview} disabled={!canEditDuplicateReview()} >
          <DropdownToggle id="duplicate-review" caret color={Order.getReviewStatusColor(order.duplicateReviewStatus)} className="d-flex align-items-center">
            <i className='bx bxs-user-account bx-xs' />
            <span className='d-inline me-3 ms-2'>{order.duplicateReviewStatus ? Order.getReviewStatusName(order.duplicateReviewStatus) : 'Duplicate Review'}</span>
            {canEditDuplicateReview() && <i className="fas fa-caret-down" />}
          </DropdownToggle>
          {order.duplicateReviewStatus && <UncontrolledTooltip placement="top" target="duplicate-review">Duplicate Review</UncontrolledTooltip>}
          <DropdownMenu className="duplicate-review-dropdown">
            {canActivateDuplicateReview() && <DropdownItem onClick={() => { setDuplicateReviewStatus(Order.REVIEW_STATUS_ACTIVE) }}>Activate</DropdownItem>}
            {canIgnoreDuplicateReview() && <DropdownItem onClick={() => { setDuplicateReviewStatus(Order.REVIEW_STATUS_IGNORE) }}>Ignore</DropdownItem>}
            {canResolveDuplicateReview() && <DropdownItem onClick={() => { setDuplicateReviewStatus(Order.REVIEW_STATUS_HIGH_RISK) }}>High Risk</DropdownItem>}
            {canResolveDuplicateReview() && <DropdownItem onClick={() => { setDuplicateReviewStatus(Order.REVIEW_STATUS_FRAUD) }}>Fraud</DropdownItem>}
          </DropdownMenu>
        </Dropdown>}

      {/* Under Review */}
      {canViewUnderReview() &&
        <Dropdown className="d-inline-block under-review-dropdown review-action-buttons pe-2" isOpen={underReviewDropdownOpen} toggle={toggleUnderReview} disabled={!canEditUnderReview()}>
          <DropdownToggle id="under-review" caret color={Order.getReviewStatusColor(order.underReviewStatus)} className="d-flex align-items-center">
            <i className='bx bx-search-alt-2 bx-xs' />
            <span className='d-inline me-3 ms-2'>{order.underReviewStatus ? Order.getReviewStatusName(order.underReviewStatus) : 'Under Review'}</span>
            {canEditUnderReview() && <i className="fas fa-caret-down" />}
          </DropdownToggle>
          {order.underReviewStatus && <UncontrolledTooltip placement="top" target="under-review">Under Review</UncontrolledTooltip>}
          <DropdownMenu className="under-review-dropdown">
            {canActivateUnderReview() && <DropdownItem onClick={() => { setUnderReviewStatus(Order.REVIEW_STATUS_ACTIVE) }}>Activate</DropdownItem>}
            {canIgnoreUnderReview() && <DropdownItem onClick={() => { setUnderReviewStatus(Order.REVIEW_STATUS_IGNORE) }}>Ignore</DropdownItem>}
            {canResolveUnderReview() && <DropdownItem onClick={() => { setUnderReviewStatus(Order.REVIEW_STATUS_HIGH_RISK) }}>High Risk</DropdownItem>}
            {canResolveUnderReview() && <DropdownItem onClick={() => { setUnderReviewStatus(Order.REVIEW_STATUS_FRAUD) }}>Fraud</DropdownItem>}
          </DropdownMenu>
        </Dropdown>}

      {/* On Hold Review */}
      {canViewOnHoldReview() &&
        <Dropdown className="d-inline-block on-hold-review-dropdown review-action-buttons pe-2" isOpen={onHoldReviewDropdownOpen} toggle={toggleOnHoldReview} disabled={!canEditOnHoldReview()}>
          <DropdownToggle id="on-hold" caret color={Order.getReviewStatusColor(order.onHoldReviewStatus)}>
            <i className="fas fa-hand-paper" />
            <span className='d-inline me-3 ms-2'>{order.onHoldReviewStatus ? Order.getReviewStatusName(order.onHoldReviewStatus) : 'On Hold'}</span>
            {order.onHoldReviewStatus && <UncontrolledTooltip placement="top" target="on-hold">On Hold</UncontrolledTooltip>}
            {canEditOnHoldReview() && <i className="fas fa-caret-down" />}
          </DropdownToggle>
          <DropdownMenu className="on-hold-review-dropdown">
            {canActivateOnHoldReview() && <DropdownItem onClick={() => { setOnHoldReviewStatus(Order.REVIEW_STATUS_ACTIVE) }}>Activate</DropdownItem>}
            {canIgnoreOnHoldReview() && <DropdownItem onClick={() => { setOnHoldReviewStatus(Order.REVIEW_STATUS_IGNORE) }}>Ignore</DropdownItem>}
          </DropdownMenu>
        </Dropdown>}

      {/* Cancel Order */}
      {!isOrderCompleted() && !isOrderDraft() && !isOrderCancelled() &&
        <ButtonGroup>
          <Button color="danger" className="square-icon-btn ms-5" onClick={() => setCancelConfirmationVisible(true)}>
            <i className="mdi mdi-close font-size-18 mt-1" />
          </Button>
          <Button color="danger" className="btn py-1" onClick={() => setCancelConfirmationVisible(true)}>
            Cancel Order
          </Button>
        </ButtonGroup>
      }

      {/* Uncancel Order */}
      {canUncancelOrder() &&
        <ButtonGroup>
          <Button color="dark" className="square-icon-btn ms-5" onClick={() => setUncancelConfirmationVisible(true)}>
            <i className="mdi mdi-backup-restore font-size-18 mt-1" />
          </Button>
          <Button color="dark" className="btn py-1" onClick={() => setUncancelConfirmationVisible(true)}>
            Uncancel Order
          </Button>
        </ButtonGroup>
      }

      {/* Delete Order */}
      {iAmGranted(perms.delete_order) && isOrderDraft() &&
        <ButtonGroup>
          <Button color="danger" className="square-icon-btn ms-5" onClick={() => setDeleteConfirmationVisible(true)} disabled={deleteInProgress}>
            <i className="bx bx-trash font-size-18 mt-1" />
          </Button>
          <Button color="danger" className="btn py-1" onClick={() => setDeleteConfirmationVisible(true)} disabled={deleteInProgress}>
            Delete Order
          </Button>
        </ButtonGroup>
      }
    </div>

    {/********* MODALS *********/}

    {/* Accounting Review Modal */}
    {
      accountingReviewConfirmationVisible && <Confirmation
        style={{ backgroundColor: 'white', minWidth: 600 }}
        title="Accounting Review"
        confirmBtnText="Confirm"
        cancelBtnText="Close"
        onConfirm={formikAccountingReview.handleSubmit}
        onCancel={() => { setAccountingReviewConfirmationVisible(false); formikAccountingReview.resetForm(); setAccountingReviewStatus(null) }}
        closeOnClickOutside={false}
        reverseButtons={false}
      >
        <div className="my-3 mx-4">
          {accountingReviewStatus && accountingReviewStatus != Order.REVIEW_STATUS_IGNORE && <>
            <div className="text-start">
              <Label className="form-label">Review reason</Label>
              <TextareaAutosize
                minRows={3}
                maxRows={7}
                className={classnames("form-control", { "is-invalid": !!formikAccountingReview.errors.accountingReviewReason })}
                name="accountingReviewReason"
                onChange={formikAccountingReview.handleChange}
                onFocus={onFieldFocusedAccountingReview}
                value={formikAccountingReview.values.accountingReviewReason}
              />
            </div>
            {!!formikAccountingReview.errors.accountingReviewReason && <FormFeedback type="invalid" className="d-block">{formikAccountingReview.errors.accountingReviewReason}</FormFeedback>}
          </>}
          {accountingReviewStatus == Order.REVIEW_STATUS_IGNORE &&
            <div>Are you sure you want to change accounting review status to <strong>{Order.getReviewStatusName(accountingReviewStatus)}</strong>?</div>
          }
        </div>
      </Confirmation>
    }

    {/* Duplicate Review Modal */}
    {
      duplicateReviewConfirmationVisible && <Confirmation
        style={{ backgroundColor: 'white', minWidth: 600 }}
        title="Duplicate Review"
        confirmBtnText="Confirm"
        cancelBtnText="Close"
        onConfirm={formikDuplicateReview.handleSubmit}
        onCancel={() => { setDuplicateReviewConfirmationVisible(false); formikDuplicateReview.resetForm(); setDuplicateReviewStatus(null) }}
        closeOnClickOutside={false}
        reverseButtons={false}
      >
        <div className="my-3 mx-4">
          {duplicateReviewStatus && duplicateReviewStatus != Order.REVIEW_STATUS_IGNORE && <>
            <div className="text-start">
              <Label className="form-label">Review reason</Label>
              <TextareaAutosize
                minRows={3}
                maxRows={7}
                className={classnames("form-control", { "is-invalid": !!formikDuplicateReview.errors.duplicateReviewReason })}
                name="duplicateReviewReason"
                onChange={formikDuplicateReview.handleChange}
                onFocus={onFieldFocusedDuplicateReview}
                value={formikDuplicateReview.values.duplicateReviewReason}
              />
            </div>
            {!!formikDuplicateReview.errors.duplicateReviewReason && <FormFeedback type="invalid" className="d-block">{formikDuplicateReview.errors.duplicateReviewReason}</FormFeedback>}
          </>}
          {duplicateReviewStatus == Order.REVIEW_STATUS_IGNORE &&
            <div>Are you sure you want to change duplicate review status to <strong>{Order.getReviewStatusName(duplicateReviewStatus)}</strong>?</div>
          }
        </div>
      </Confirmation>
    }

    {/* Under Review Modal */}
    {
      underReviewConfirmationVisible && <Confirmation
        style={{ backgroundColor: 'white', minWidth: 600 }}
        title="Under Review"
        confirmBtnText="Confirm"
        cancelBtnText="Close"
        onConfirm={formikUnderReview.handleSubmit}
        onCancel={() => { setUnderReviewConfirmationVisible(false); formikUnderReview.resetForm(); setUnderReviewStatus(null) }}
        closeOnClickOutside={false}
        reverseButtons={false}
      >
        <div className="my-3 mx-4">
          {underReviewStatus && underReviewStatus != Order.REVIEW_STATUS_IGNORE && <>
            <div className="text-start">
              <Label className="form-label">Review reason</Label>
              <TextareaAutosize
                minRows={3}
                maxRows={7}
                className={classnames("form-control", { "is-invalid": !!formikUnderReview.errors.underReviewReason })}
                name="underReviewReason"
                onChange={formikUnderReview.handleChange}
                onFocus={onFieldFocusedUnderReview}
                value={formikUnderReview.values.underReviewReason}
              />
            </div>
            {!!formikUnderReview.errors.underReviewReason && <FormFeedback type="invalid" className="d-block">{formikUnderReview.errors.underReviewReason}</FormFeedback>}
          </>}
          {underReviewStatus == Order.REVIEW_STATUS_IGNORE &&
            <div>Are you sure you want to change under review status to <strong>{Order.getReviewStatusName(underReviewStatus)}</strong>?</div>
          }
        </div>
      </Confirmation>
    }

    {/* On Hold Review Modal */}
    {
      onHoldReviewConfirmationVisible && <Confirmation
        style={{ backgroundColor: 'white', minWidth: 600 }}
        title="On Hold Review"
        confirmBtnText="Confirm"
        cancelBtnText="Close"
        onConfirm={formikOnHoldReview.handleSubmit}
        onCancel={() => { setOnHoldReviewConfirmationVisible(false); formikOnHoldReview.resetForm(); setOnHoldReviewStatus(null) }}
        closeOnClickOutside={false}
        reverseButtons={false}
      >
        <div className="my-3 mx-4">
          {onHoldReviewStatus && onHoldReviewStatus != Order.REVIEW_STATUS_IGNORE && <>
            <div className="text-start">
              <Label className="form-label">Review reason</Label>
              <TextareaAutosize
                minRows={3}
                maxRows={7}
                className={classnames("form-control", { "is-invalid": !!formikOnHoldReview.errors.onHoldReviewReason })}
                name="onHoldReviewReason"
                onChange={formikOnHoldReview.handleChange}
                onFocus={onFieldFocusedOnHoldReview}
                value={formikOnHoldReview.values.onHoldReviewReason}
              />
            </div>
            {!!formikOnHoldReview.errors.onHoldReviewReason && <FormFeedback type="invalid" className="d-block">{formikOnHoldReview.errors.onHoldReviewReason}</FormFeedback>}
          </>}
          {onHoldReviewStatus == Order.REVIEW_STATUS_IGNORE &&
            <div>Are you sure you want to change on hold review status to <strong>{Order.getReviewStatusName(onHoldReviewStatus)}</strong>?</div>
          }
        </div>
      </Confirmation>
    }

    {/* Delete Order Modal */}
    {
      deleteConfirmationVisible && <Confirmation
        confirmBtnText="Delete"
        cancelBtnText="Close"
        style={{ backgroundColor: '#fff' }}
        reverseButtons={false}
        onConfirm={() => {
          setDeleteConfirmationVisible(false);
          removeOrder();
        }}
        onCancel={() => { setDeleteConfirmationVisible(false) }}
      >
        Are you sure you want to delete order &quot;{order.id}&quot;?
      </Confirmation>
    }

    {/* Cancel Order Modal */}
    {cancelConfirmationVisible && <Confirmation
      customIcon={cancelIcon}
      style={{ backgroundColor: 'white', minWidth: 600, paddingBottom: 35 }}
      confirmBtnText="Confirm"
      cancelBtnText="Close"
      onConfirm={formikCancellation.handleSubmit}
      onCancel={() => { setCancelConfirmationVisible(false); formikCancellation.resetForm(); }}
      closeOnClickOutside={false}
      disabled={!formikCancellation.values.cancellationReason}
      reverseButtons={false}
    >
      <div className="font-weight-600 font-size-24 mb-2">Reason For Cancellation</div>
      <FormikProvider value={formikCancellation}>
        <FieldArray
          name="cancellationReason"
          render={() => <React.Fragment>
            {Order.getCancellationReasonsList().map((item, index) => (
              <React.Fragment key={index}>
                <div className="my-3 px-3">
                  <div key={index} className="form-check mb-2">
                    <Input
                      type="radio"
                      id={`cancellationReason-${index}`}
                      name="cancellationReason"
                      onChange={() => formikCancellation.setFieldValue('cancellationReason', item.value)}
                      value={item.value}
                      checked={formikCancellation.values.cancellationReason === item.value}
                      onFocus={onFieldFocusedCancellation}
                    />
                    <label htmlFor={`cancellationReason-${index}`} className="form-check-label">{item.label}</label>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </React.Fragment>} />
      </FormikProvider>
      <div className="text-start mt-4 px-3">
        <Label className="form-label">Additional info:</Label>
        <TextareaAutosize
          minRows={3}
          maxRows={7}
          placeholder="Your message..."
          className="form-control"
          name="cancellationComment"
          onChange={formikCancellation.handleChange}
          onFocus={onFieldFocusedCancellation}
          value={formikCancellation.values.cancellationComment}
        />
      </div>
    </Confirmation>}

    {/* Uncancel Order Modal */}
    {
      uncancelConfirmationVisible && <Confirmation
        confirmBtnText="Confirm"
        cancelBtnText="Close"
        style={{ backgroundColor: '#fff' }}
        reverseButtons={false}
        onConfirm={() => {
          setUncancelConfirmationVisible(false);
          handleUncancelOrder();
        }}
        onCancel={() => { setUncancelConfirmationVisible(false) }}
      >
        <span style={{ color: '#556EE6' }}>Are you sure you want to uncancel this order ?</span>
      </Confirmation>
    }

  </React.Fragment >
}

SectionOrderReviews.propTypes = {
  order: PropTypes.object,
  id: PropTypes.number,
  refreshHandler: PropTypes.func,
};

export default SectionOrderReviews;
