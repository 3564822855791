import React from "react";
import { useSelector } from "react-redux";

const StepDocsDelivered = () => {

  /********** STATE **********/

  const { order } = useSelector(state => state.Order.Single);

  return (
    <React.Fragment>
      <div className="mb-1">
        <span className="me-2">Customer:</span>
        <strong>{order?.signers[0].fullName}</strong>
      </div>
      <div className="mb-1">
        <span className="me-2">Signing address:</span>
        <strong>{order?.signers[0].address}</strong>
      </div>
      <div className="mb-1 text-break">
        <span className="me-2">City:</span>
        <strong>{order?.signers[0].city}</strong>
      </div>
      <div className="mb-1">
        <span className="me-2">State or Province:</span>
        <strong>{order?.signers[0].state}</strong>
      </div>
      <div className="mb-1">
        <span className="me-2">ZIP Code:</span>
        <strong>{order?.signers[0].zip}</strong>
      </div>
      <div className="divider-faded divider-sm" />

      <h6>Action: </h6>
      <ul>
        <li>
          Please inform the customer about the items you will be collecting at the siging.
        </li>
      </ul>
    </React.Fragment>
  )
};

export default StepDocsDelivered;