import SpinnerChase from "components/Shared/SpinnerChase";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader, Row, Col, CardTitle, CardBody, Alert, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { doAppointmentsListCleanup, getAppointmentsList } from "store/actions";
import { Link } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { formatTimestamp, formats } from "helpers/dateHelper";
import { map } from "lodash";

const Appointments = () => {
  const dispatch = useDispatch();

  /********** STATE **********/
  const { appointments, isLoadInProgress, appointmentsError, totalCount, listParams } = useSelector(state => state.Dashboard.AppointmentsList);

  const [pagination, setPagination] = useState({
    totalCount,
    page: listParams.page,
    pageSize: listParams.pageSize,
  });

  const { page, pageSize } = pagination;

  const pageCount = Math.ceil(totalCount / pageSize) || 1;

  /********** OTHER **********/
  const refreshOrders = () => dispatch(getAppointmentsList(listParams));

  // runs once on component mount
  useEffect(() => {
    // get Appointments
    refreshOrders()
    return () => {
      // state cleanup on component unmount
      dispatch(doAppointmentsListCleanup());
    }
  }, []);

  // runs whenever page number changes, including first render
  useEffect(() => {
    dispatch(getAppointmentsList({ ...listParams, page }));
  }, [page]);

  // runs whenever total count changes
  useEffect(() => {
    setPagination(current => ({
      ...current,
      totalCount,
    }));
  }, [totalCount]);

  /********** HANDLERS **********/
  const handlePageClick = page => event => {
    // prevent redirecting
    event.preventDefault();

    setPagination(current => ({
      ...current,
      page,
    }));
  };

  return <>
    <Card className="subscriptions-card">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <CardTitle>Today’s Appointments</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pt-1 horizontal-scroll-table">
        <table className="table">
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Customer Name</th>
              <th>Notary Name</th>
              <th>Date and Time</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            {appointments?.length > 0 && appointments.map((item, index) => <tr key={index}>
              <td><Link to={route(routes.view_order, item.id)}>{item.id}</Link></td>
              <td>{item.customerName}</td>
              <td>{item.notaryFullName}</td>
              <td>{formatTimestamp(item.meetingTs, formats.DATETIME)}</td>
              <td>{item.notaryTimezone}</td>
            </tr>
            )}
            {appointments?.length === 0 && <tr>
              <td className="table-empty" colSpan="7">No appointments found</td>
            </tr>
            }
            {!!appointmentsError && <tr>
              <td className="table-empty" colSpan="7">
                <Alert color="danger" className="fade show text-center mb-0">
                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load appointments
                </Alert>
              </td>
            </tr>}
            {isLoadInProgress && <tr>
              <td className="table-empty" colSpan="7">
                <SpinnerChase className="sm" />
              </td>
            </tr>}
          </tbody>
          <tfoot>
            {pageCount > 1 &&
              <tr>
                <td colSpan={5}>
                  <Pagination className="pagination pagination-rounded justify-content-center mt-3">
                    <PaginationItem disabled={page === 1}>
                      <PaginationLink
                        previous
                        href=""
                        onClick={handlePageClick(page - 1)}
                      />
                    </PaginationItem>
                    {map(Array(pageCount), (_item, i) => (
                      <PaginationItem active={i + 1 === page} key={i}>
                        <PaginationLink
                          onClick={handlePageClick(i + 1)}
                          href=""
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={page === pageCount}>
                      <PaginationLink
                        next
                        href=""
                        onClick={handlePageClick(page + 1)}
                      />
                    </PaginationItem>
                  </Pagination>
                </td>
              </tr>
            }
          </tfoot>
        </table>
      </CardBody>
    </Card>
  </>
}

export default Appointments;