import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Button, Form, Label, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import Select from 'react-select';
import { useFormik } from "formik";
import { extractDtFiltersFromUrl, hasNonEmpty, dictionaryToSelectOptions, getGranularStatusOptions } from "helpers/utilHelper";
import { applySupportCaseDtFilters, clearSupportCaseDtFilters, patchSupportCaseDtFilters, unsetSupportCaseDtFilters } from "store/actions";
import { isEmpty, omit } from "lodash";
import Order from 'model/order';
import SupportCase from "model/supportCase";
import VidRequest from "model/vidRequest";

const DataTableFilters = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const filters = useSelector(state => state.SupportCase.DtFilters);

  // is the filters form visible or not
  // used to show/hide the filters form
  const [isVisible, setIsVisible] = useState(false);

  /********** FORM CONFIG **********/

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      ...filters,
      incAlert: [],
    },
    onSubmit: values => applyFilters(values),
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // extract filters from url
    // they will be applied by default as the user enters the screen
    const filters = extractDtFiltersFromUrl();
    // if url filters are not empty
    if (!isEmpty(filters)) {
      // in this case, replace the old filters with the relevant ones
      applyFilters(filters);
    } else {
      // make sure to call this even if there are no filters in the url
      // because we need to switch the '_set' flag
      dispatch(patchSupportCaseDtFilters(filters));
    }
    return () => {
      // switch back the '_set' flag
      dispatch(unsetSupportCaseDtFilters());
    }
  }, []);

  /********** EVENT HANDLERS **********/

  // shows/hides the filters form
  const toggleFilters = () => setIsVisible(!isVisible);

  // event handler for the 'apply-filters' button
  const applyFilters = values => {
    dispatch(applySupportCaseDtFilters(values));
  }

  // event handler for the 'clear-filters' button
  const clearFilters = () => {
    // reset form fields
    formik.setValues({
      ...formik.initialValues,
      incAlert: [], // Clear selected values in "Alert"
    });
    // reset state
    dispatch(clearSupportCaseDtFilters());
  }

  // load state filters into local filters
  // state filters = applied filters that are send to backend
  // local filters = state vars bound to form controls
  // this is fired each time the offcanvas is opened
  // to discard anything the user might have typed in the fields (and not applied) before closing the offcanvas
  const initLocalFilters = () => formik.setValues(filters);

  /********** OTHER **********/

  const setAlertOptions = (alertList) => {
    const selectedValues = alertList.map((serviceItem) => serviceItem.value);
    formik.setFieldValue("incAlert", selectedValues);
  }

  const statusOptions = getGranularStatusOptions();

  const getAlertOptions = () => dictionaryToSelectOptions(SupportCase.getAlertOptions());

  const hasFilters = () => hasNonEmpty(omit(filters, "_set"));

  const getStatusOptions = () => dictionaryToSelectOptions(Order.getStatusMap())

  const getCustomerLocationOptions = () => dictionaryToSelectOptions(Order.getCustomerLocationMap());

  const getSupportCaseOptions = () => dictionaryToSelectOptions(SupportCase.getStatusMap());

  const getVidStatusOptions = () => dictionaryToSelectOptions(VidRequest.getStatusesForSupportCases());

  const getDateOptions = () => dictionaryToSelectOptions(SupportCase.getDateMap());

  return <React.Fragment>
    <div className="btn-group ms-2 mb-2" >
      <Button type="button" color="dark" onClick={toggleFilters}>
        <i className="mdi mdi-filter-variant me-1" />Filters
      </Button>
      {hasFilters() && <Button type="button" color="dark" onClick={clearFilters}>
        <i className="mdi mdi-close" />
      </Button>}
    </div>
    <Offcanvas direction="end" isOpen={isVisible} toggle={toggleFilters} onOpened={initLocalFilters}>
      <OffcanvasHeader toggle={toggleFilters}>Filters</OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="mb-3">
            <Label>Support case ID</Label>
            <Input type="text" className="form-control" name="id" onChange={formik.handleChange} value={formik.values.id} />
          </div>
          <div className="mb-3">
            <Label>Status</Label>
            <Select
              classNamePrefix="select2-selection"
              name="status"
              options={getSupportCaseOptions()}
              onChange={selected => formik.setFieldValue("status", selected.value)}
              value={getSupportCaseOptions().find(option => option.value === formik.values.status)}
            />
          </div>
          <div className="mb-3">
            <Label>Order status</Label>
            <Select
              classNamePrefix="select2-selection"
              name="orderStatus"
              options={getStatusOptions()}
              onChange={selected => formik.setFieldValue("orderStatus", selected.value)}
              value={getStatusOptions().find(option => option.value === formik.values.orderStatus)}
            />
          </div>
          <div className="mb-3">
            <Label>Vid Status</Label>
            <Select
              classNamePrefix="select2-selection"
              name="vidStatus"
              options={getVidStatusOptions()}
              onChange={selected => formik.setFieldValue("vidStatus", selected.value)}
              value={getVidStatusOptions().find(option => option.value === formik.values.vidStatus)}
            />
          </div>
          <div className="mt-2 mb-3">
            <Label>Alerts</Label>
            <Select
              classNamePrefix="select2-selection"
              name="incAlert"
              isMulti
              options={getAlertOptions()}
              onChange={selected => setAlertOptions(selected)}
              value={getAlertOptions().filter(option => formik.values.incAlert.includes(option.value))}
            />
          </div>
          <div className="mb-3">
            <Label>Date</Label>
            <Select
              classNamePrefix="select2-selection"
              name="created"
              options={getDateOptions()}
              onChange={selected => formik.setFieldValue("created", selected.value)}
              value={getDateOptions().find(option => option.value === formik.values.created)}
            />
          </div>
          <div className="mb-3">
            <Label>Customer Name</Label>
            <Input type="text" className="form-control" name="customerFullName" onChange={formik.handleChange} value={formik.values.customerFullName} />
          </div>
          <div className="mb-3">
            <Label>Dealer Name</Label>
            <Input type="text" className="form-control" name="dealerFullName" onChange={formik.handleChange} value={formik.values.dealerFullName} />
          </div>
          <div className="mb-3">
            <Label>Order number </Label>
            <Input type="text" className="form-control" name="orderId" onChange={formik.handleChange} value={formik.values.orderId} />
          </div>
          <div className="mb-3">
            <Label>Phone number </Label>
            <Input type="text" className="form-control" name="customerPhone" onChange={formik.handleChange} value={formik.values.customerPhone} />
          </div>
          <div className="mb-3">
            <Label>Type</Label>
            <Select
              classNamePrefix="select2-selection"
              name="customerLocation"
              options={getCustomerLocationOptions()}
              onChange={selected => formik.setFieldValue("customerLocation", selected.value)}
              value={getCustomerLocationOptions().find(option => option.value === formik.values.customerLocation)}
            />
          </div>
          <div className="mb-3">
            <Label>Assignee </Label>
            <Input type="text" className="form-control" name="assigneeFullName" onChange={formik.handleChange} value={formik.values.assigneeFullName} />
          </div>
          <div className="text-end">
            <Button type="button" color="primary" className="me-2" onClick={formik.handleSubmit}>
              <i className="mdi mdi-filter me-1" />Apply
            </Button>
            <Button type="button" color="warning" onClick={clearFilters}>
              <i className="mdi mdi-eraser me-1" />Clear
            </Button>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  </React.Fragment>
}

export default DataTableFilters;