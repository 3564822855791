import React, { useEffect } from 'react';
import { Button, Col, Form, FormFeedback, Input, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "helpers/utilHelper";
import { FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import {
  doNotaryFormCleanup,
  updateNotaryAccounting,
} from "store/orderFees/actions";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { ValidationException } from "helpers/errorHelper";
import TotalCosts from "pages/Order/Partial/Section/Accounting/Components/TotalCosts";
import classnames from "classnames";
import AdditionalFee from 'model/additionalFee';
import additionalVehicleIcon from "assets/images/order/directions-car.svg";

// calculate fees total
const getTotalCost = fees => {
  if (!fees) return 0;

  return fees.reduce((accum, val) => accum + (Number(val.price) * Number(val.count ?? 1)), 0);
};

const FormNotaryEdit = ({ order, orderFees, orderNotary, isActive }) => {

  let { id } = useParams();
  id = parseInt(id);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isSaveInProgress, savedNotary, saveNotaryError } = useSelector(state => state.OrderFees.Form);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    accountingNotes: orderNotary.accountingNotes || '',
    // for better readability and easier management
    // fees must be a list of key-value pairs consisting of the fee id, price and count
    fees: orderFees?.map(fee => ({ id: fee.additionalFeeId, price: fee.price == "" || fee.price == null ? "Not set" : Number(fee.price), count: fee.count })),
    notaryId: orderNotary.notaryId,
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      accountingNotes: Yup.string().trim(),
      fees: Yup.array().of(
        Yup.object().shape({
          price: Yup.mixed().required('Field is required')
        })
      ),
      notaryId: Yup.number()
    }),
    onSubmit: values => dispatch(updateNotaryAccounting(values, id)),
  });

  // check if exist fees with price not set
  const hasUnsetPrice = formik.values.fees?.some(fee => fee.price === AdditionalFee.FEE_PRICE_NOT_SET);

  /********** EFFECTS **********/

  useEffect(() => {
    // show feedback only for active tab
    // otherwise the message will be shown times the notary tabs
    if (isActive) {
      if (savedNotary === true) {
        showSuccess("Notary fee has been saved");
        formik.setSubmitting(false);
      } else if (savedNotary === false) {
        showError("Unable to save fee.");
        // see if the save failed due to validation
        if (saveNotaryError instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(saveNotaryError.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      }
    }
  }, [savedNotary]);

  // runs on component unmount
  // to cleanup form
  useEffect(() => {
    return () => {
      dispatch(doNotaryFormCleanup());
    }
  }, []);

  /********** EVENT HANDLERS **********/

  // focus event handler used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  // focus event handler
  // used to clear field errors
  const onArrayFieldFocused = (arrayName, index, fieldName) => () => {
    const errors = formik.errors;
    if (errors[arrayName] && errors[arrayName][index] && errors[arrayName][index][fieldName]) {
      delete errors[arrayName][index][fieldName];
      formik.setStatus(errors);
    }
  }

  // key down event handler that disables the default behavior of number inputs of increasing/decreasing the value when arrow keys are pressed
  const onFieldKeyDown = e => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
    }
  }

  // key down event handler that disables the default behavior of number inputs of increasing/decreasing the value when scrolling
  const onFieldWheel = e => {
    e.target.blur()
  };

  return <React.Fragment>
    <Form>
      <div className="mb-2 col-sm-7">
        <Label className="col-form-label">Notary name</Label>
        <Input
          type="text"
          className="form-control"
          name="notaryName"
          value={orderNotary?.notaryFullName}
          disabled={true}
        />
      </div>

      <FormikProvider value={formik}>
        <FieldArray name="fees" render={() => <React.Fragment>
          <div className="mb-2 col-sm-7">
            {orderFees?.map((fee, index) => (
              <React.Fragment key={fee.id}>
                <Label className="col-form-label">{fee.additionalFeeName}</Label>
                <div className='d-flex'>
                  {/* Additional Vehicles Label */}
                  {fee.additionalFeeId === AdditionalFee.ID_ADDITIONAL_VEHICLE && orderFees?.find(item => item.additionalFeeId === AdditionalFee.ID_ADDITIONAL_VEHICLE)?.count > 1 && (
                    <div className='multiple-times-label d-flex'>
                      <img src={additionalVehicleIcon} />
                      {orderFees?.find(item => item.additionalFeeId === AdditionalFee.ID_ADDITIONAL_VEHICLE)?.count}x Vehicles
                    </div>
                  )}
                  <Input
                    type="number"
                    min={0}
                    className="form-control disable-input-arrows"
                    name={`fees.${index}.price`}
                    placeholder={'Not set'}
                    onChange={formik.handleChange}
                    onFocus={onArrayFieldFocused("fees", index, "price")}
                    onKeyDown={onFieldKeyDown}
                    onWheel={onFieldWheel}
                    value={formik.values.fees?.[index]?.price}
                    invalid={!!formik.errors.fees?.[index]?.price}
                  />
                </div>
                {!!formik.errors.fees?.[index]?.price && <FormFeedback type="invalid">{formik.errors.fees[index].price}</FormFeedback>}
              </React.Fragment>
            ))}
          </div>
        </React.Fragment>} />
      </FormikProvider>

      <div className="mb-2 col-sm-7">
        <Label className="col-form-label">Notary Notes For Accounting</Label>
        <textarea
          className={classnames("form-control", { "is-invalid": !!formik.errors.accountingNotes })}
          placeholder="Your message..."
          name="accountingNotes"
          onChange={formik.handleChange}
          onFocus={onFieldFocused}
          value={formik.values.accountingNotes}
        />
        {!!formik.errors.accountingNotes && <FormFeedback type="invalid">{formik.errors.accountingNotes}</FormFeedback>}
      </div>

      {!hasUnsetPrice && <TotalCosts label={'Notary’s Total'} value={getTotalCost(formik.values.fees)} className="col-sm-7 mt-4" />}

      <Col xs="auto col-sm-7 mt-4">
        <div className="text-end">
          <Button type="button" color="secondary" className="ms-2 mb-2" onClick={() => navigate(route(routes.view_order, id))}>
            Cancel
          </Button>
          {orderNotary.canAmendNotaryAmount && <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
            {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
            Save
          </Button>}
        </div>
      </Col>
    </Form>
  </React.Fragment>
}

FormNotaryEdit.propTypes = {
  order: PropTypes.object,
  orderFees: PropTypes.array,
  orderNotary: PropTypes.object,
  isActive: PropTypes.bool,
};

export default FormNotaryEdit;