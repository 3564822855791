import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Row, Form, Label, Input, FormFeedback, Button, Card, CardHeader, CardBody } from "reactstrap";
import PlacesAutocomplete from "components/Shared/PlacesAutocomplete";
import classnames from "classnames";
import * as Yup from "yup";
import { useFormik, FormikProvider, FieldArray } from "formik";
import Col from "components/Shared/Col";
import { doOrderFormCleanup, updateOrderCustomer } from "store/actions";
import regx from "constants/regx";
import { capitalize, getAddressComponents, nullsToEmptyStrings, phoneHasNoOfDigits, showError, showSuccess, toSelectOptions } from "helpers/utilHelper";
import { ValidationException } from "helpers/errorHelper";
import Select from "react-select";
import UsStates from "constants/usState";

const FormEditCustomer = props => {

  const { defaultValues, finishedHandler } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/

  // get redux state from the store
  const { isSaveInProgress, saved, saveError } = useSelector(state => state.Order.Form);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    signers: [],
    ...nullsToEmptyStrings(defaultValues, true),
  };

  const signerValidationSchema = (isNotaryRequired, index) => {
    let schema = {
      firstName: Yup.string().trim().required('Field is required').min(1, 'Field requires at least 1 character'),
      lastName: Yup.string().trim().required('Field is required').min(1, 'Field requires at least 1 character'),
      email: Yup.string().trim().required('Field is required').email('Invalid email address'),
      phone: Yup.string().trim().required('Field is required').matches(regx.phone, 'Invalid phone number').test('phone', 'Field requires exactly 10 digits', (value) => phoneHasNoOfDigits(value)),
      ssn: Yup.string().matches(/^\d{4}$/, "The field should have exactly 4 digits").required('Field is required')
    };

    if (isNotaryRequired && index === 0) {
      schema = {
        ...schema,
        address: Yup.string().trim().required('Field is required'),
        city: Yup.string().trim().required('Field is required'),
        state: Yup.string().trim().required('Field is required'),
        zip: Yup.string().trim().required('Field is required'),
      };
    }

    return Yup.object().shape(schema);
  };

  const formValidationSchema = {
    signers: Yup.array().of(
      Yup.lazy((value, index) => signerValidationSchema(defaultValues?.isNotaryRequired, index))
    ).min(1, 'Please add at least one signer'),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(formValidationSchema),
    onSubmit: values => dispatch(updateOrderCustomer(values, props.id)),
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderFormCleanup());
    }
  }, []);

  // runs whenever the 'saved' flag changes
  // which happens after a save-order attempt
  useEffect(() => {
    if (saved === true) {
      showSuccess(`Customer information has been saved`);
      finishedHandler(true);
    } else if (saved === false) {
      showError('Unable to save customer information');
      // see if the save failed due to validation
      if (saveError instanceof ValidationException) {
        // show an error on each invalid field
        for (const [name, message] of Object.entries(saveError.fields)) {
          formik.setFieldError(name, message);
        }
      }
      // enable the save button
      formik.setSubmitting(false);
    }
  }, [saved]);

  useEffect(() => {
    if (defaultValues?.signers.length == 2) {
      formik.setFieldValue('signers[1]', {
        firstName: defaultValues?.signers[1].firstName,
        lastName: defaultValues?.signers[1].lastName,
        phone: defaultValues?.signers[1].phone,
        email: defaultValues?.signers[1].email,
        ssn: defaultValues?.signers[1].ssn
      });
    }
  }, []);

  /********** EVENT HANDLERS **********/

  // on change event handler that capitalizes user input
  const capitalizeTextOnChange = event => {
    const { name, id } = event.target;
    formik.setFieldValue(name || id, capitalize(event.target.value))
  };

  // focus event handler
  // used to clear field errors
  const onArrayFieldFocused = (arrayName, index, fieldName) => {
    const errors = formik.errors;
    if (errors[arrayName] && errors[arrayName][index] && errors[arrayName][index][fieldName]) {
      delete errors[arrayName][index][fieldName];
      formik.setStatus(errors);
    }
  }

  // adds or removes the add signer fields
  const toggleAddSigner = (e, arrayHelpers) => {
    if (e.target.checked) {
      arrayHelpers.push(getEmptyAdditionalSigner());
    } else {
      arrayHelpers.remove(1);
    }
  }

  const fillInAddress = () => place => {
    const addressComponents = getAddressComponents(place);
    formik.setFieldValue(`signers.0.zip`, addressComponents.zip);
    formik.setFieldValue(`signers.0.city`, addressComponents.city);
    formik.setFieldValue(`signers.0.state`, addressComponents.state);
    formik.setFieldValue(`signers.0.address`, addressComponents.address);
    formik.setFieldValue(`signers.0.latitude`, place.geometry.location.lat());
    formik.setFieldValue(`signers.0.longitude`, place.geometry.location.lng());
  }

  /********** OTHER **********/

  const getEmptyAdditionalSigner = () => ({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    ssn: '',
  });

  return <React.Fragment>
    <Card className="expand-v overflow-hidden">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Customer Information</div>
          </Col>
          <Col xs="auto" className="text-end">
            <Button type="button" color="primary" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
              Save
            </Button>
            <Button type="button" color="secondary" className="ms-2" onClick={() => finishedHandler()}>
              <i className="mdi mdi-chevron-left me-1" />Cancel
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="p-0">
        <Form className="pt-4">
          <FormikProvider value={formik}>
            <FieldArray name="signers" render={arrayHelpers => <React.Fragment>
              {formik.values.signers.map((signer, index) => <div className={classnames('card-section', { 'blue pt-3 pb-1': index == 1 })} key={index}>
                <Row className="mb-4">
                  <Col xl="6">
                    <Label>{index == 1 ? 'First name of additional signer' : 'First name'} *</Label>
                    <Input
                      type="text"
                      className="form-control"
                      name={`signers.${index}.firstName`}
                      onChange={capitalizeTextOnChange}
                      onFocus={() => onArrayFieldFocused('signers', index, 'firstName')}
                      value={formik.values.signers[index]?.firstName}
                      invalid={!!formik.errors.signers && !!formik.errors.signers[index]?.firstName}
                    />
                    {!!formik.errors.signers && !!formik.errors.signers[index]?.firstName && <FormFeedback type="invalid">{formik.errors.signers[index].firstName}</FormFeedback>}
                  </Col>
                  <Col xl="6">
                    <Label>{index == 1 ? 'Last name of additional signer' : 'Last name'} *</Label>
                    <Input
                      type="text"
                      className="form-control"
                      name={`signers.${index}.lastName`}
                      onChange={capitalizeTextOnChange}
                      onFocus={() => onArrayFieldFocused('signers', index, 'lastName')}
                      value={formik.values.signers[index]?.lastName}
                      invalid={!!formik.errors.signers && !!formik.errors.signers[index]?.lastName}
                    />
                    {!!formik.errors.signers && !!formik.errors.signers[index]?.lastName && <FormFeedback type="invalid">{formik.errors.signers[index].lastName}</FormFeedback>}
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col xl="6">
                    <Label>{index == 1 ? 'Phone of additional signer' : 'Phone'} *</Label>
                    <Input
                      type="text"
                      className="form-control"
                      name={`signers.${index}.phone`}
                      onChange={capitalizeTextOnChange}
                      onFocus={() => onArrayFieldFocused('signers', index, 'phone')}
                      value={formik.values.signers[index]?.phone}
                      invalid={!!formik.errors.signers && !!formik.errors.signers[index]?.phone}
                    />
                    {!!formik.errors.signers && !!formik.errors.signers[index]?.phone && <FormFeedback type="invalid">{formik.errors.signers[index].phone}</FormFeedback>}
                  </Col>
                  <Col xl="6">
                    <Label>{index == 1 ? 'Email of additional signer' : 'Email'} *</Label>
                    <Input
                      type="text"
                      className="form-control"
                      name={`signers.${index}.email`}
                      onChange={formik.handleChange}
                      onFocus={() => onArrayFieldFocused('signers', index, 'email')}
                      value={formik.values.signers[index]?.email}
                      invalid={!!formik.errors.signers && !!formik.errors.signers[index]?.email}
                    />
                    {!!formik.errors.signers && !!formik.errors.signers[index]?.email && <FormFeedback type="invalid">{formik.errors.signers[index].email}</FormFeedback>}
                  </Col>
                  <Row className="mt-4">
                    <Col xl="6" className="mb-4">
                      <Label>{index == 1 ? 'Last 4 digits of SSN of additional signer' : 'Last 4 digits of SSN'} *</Label>
                      <Input
                        type="string"
                        className="form-control"
                        name={`signers.${index}.ssn`}
                        onChange={formik.handleChange}
                        onFocus={() => onArrayFieldFocused('signers', index, 'ssn')}
                        value={formik.values.signers[index]?.ssn}
                        invalid={!!formik.errors.signers && !!formik.errors.signers[index]?.ssn}
                      />
                      {!!formik.errors.signers && !!formik.errors.signers[index]?.ssn && <FormFeedback type="invalid">{formik.errors.signers[index].ssn}</FormFeedback>}
                    </Col>
                  </Row>
                </Row>
                {(index == 0) && <React.Fragment>
                  <Row className="mb-4">
                    <Col xl="6">
                      <Label>Address where signing will take place *</Label>
                      <PlacesAutocomplete
                        type="text"
                        className={classnames("form-control", { "is-invalid": !!formik.errors.signers && !!formik.errors.signers[0]?.address })}
                        placeholder="ex. 2273 Muldrow Dr"
                        name={`signers.0.address`}
                        onChange={formik.handleChange}
                        onPlaceChanged={fillInAddress()}
                        onFocus={() => onArrayFieldFocused("signers", 0, "address")}
                        value={formik.values.signers[0]?.address}
                      />
                      {!!formik.errors.signers && !!formik.errors.signers[0]?.address && <FormFeedback type="invalid">{formik.errors.signers[0].address}</FormFeedback>}
                    </Col>
                    <Col xl="6">
                      <Label>City *</Label>
                      <Input
                        type="text"
                        className="form-control"
                        name={`signers.0.city`}
                        onChange={capitalizeTextOnChange}
                        onFocus={() => onArrayFieldFocused('signers', 0, 'city')}
                        value={formik.values.signers[0]?.city}
                        invalid={!!formik.errors.signers && !!formik.errors.signers[0]?.city}
                      />
                      {!!formik.errors.signers && !!formik.errors.signers[0]?.city && <FormFeedback type="invalid">{formik.errors.signers[0].city}</FormFeedback>}
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col xl="6">
                      <Label>State or province *</Label>
                      <Select
                        classNamePrefix="select2-selection"
                        name={`signers.0.state`}
                        options={usStates}
                        onChange={selected => formik.setFieldValue(`signers.0.state`, selected.value)}
                        onFocus={() => onArrayFieldFocused('signers', 0, 'state')}
                        value={usStates.find(option => option.value === formik.values.signers[0]?.state)}
                        className={!!formik.errors.signers && !!formik.errors.signers[0]?.state && 'is-invalid'} />
                      {!!formik.errors.signers && !!formik.errors.signers[0]?.state && <FormFeedback type="invalid">{formik.errors.signers[0].state}</FormFeedback>}
                    </Col>
                    <Col xl="6">
                      <Label>Zip code *</Label>
                      <Input
                        type="text"
                        className="form-control"
                        name={`signers.0.zip`}
                        onChange={formik.handleChange}
                        onFocus={() => onArrayFieldFocused('signers', 0, 'zip')}
                        value={formik.values.signers[0]?.zip}
                        invalid={!!formik.errors.signers && !!formik.errors.signers[0]?.zip}
                      />
                      {!!formik.errors.signers && !!formik.errors.signers[0]?.zip && <FormFeedback type="invalid">{formik.errors.signers[0].zip}</FormFeedback>}
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col xl="6">
                      <Label>Additional info</Label>
                      <Input
                        type="text"
                        placeholder="apartment, unit, suite number"
                        className="form-control"
                        name={`signers.0.address2`}
                        onChange={capitalizeTextOnChange}
                        onFocus={() => onArrayFieldFocused('signers', 0, 'address2')}
                        value={formik.values.signers[0]?.address2}
                      />
                    </Col>
                  </Row>
                </React.Fragment>}
                {index == 0 && <Row className="mb-4">
                  <Col>
                    <div className="form-check form-switch form-switch-lg d-inline-block">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="addSignerSwitch"
                        onChange={e => toggleAddSigner(e, arrayHelpers)}
                        defaultChecked={formik.values.signers.length == 2}
                      />
                      <Label className="form-check-label" htmlFor="addSignerSwitch" />
                    </div>
                    <Label className="mb-0" htmlFor="addSignerSwitch">Additional signer</Label>
                  </Col>
                </Row>}
              </div>)}
              {!!formik.errors.signers && typeof formik.errors.signers === 'string' && <div className="card-section">
                <FormFeedback type="invalid" className="d-block">{formik.errors.signers}</FormFeedback>
              </div>}
            </React.Fragment>} />
          </FormikProvider>
        </Form>
      </CardBody>
    </Card>
  </React.Fragment>
}

const usStates = toSelectOptions(UsStates);

FormEditCustomer.propTypes = {
  id: PropTypes.number,
  defaultValues: PropTypes.object,
  finishedHandler: PropTypes.func,
};

export default FormEditCustomer;