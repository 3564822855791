import { takeEvery, put, call } from "redux-saga/effects";

import {
  GET_DEALER_GROUP_STORE_LIST,
  GET_DEALER_REGION_STORE_LIST,
  GET_DEALER_STORE_DT,

  GET_DEALER_STORE_LIST,

  GET_SUBSCRIPTION_DT,
} from "./actionTypes";

import {
  getDealerStoreDtOk,
  getDealerStoreDtErr,

  getDealerStoreListOk,
  getDealerStoreListErr,

  getSubscriptionDtOk,
  getSubscriptionDtErr,
} from "./actions";

import {
  getDealerGroupStores,
  getDealerRegionStores,
  getDealerStoreDt,
  getDealerStores,
  getSubscriptionDt,
} from "helpers/backendHelper";

/********** DATATABLE **********/

function* onGetDealerStoreDt({ payload: { params } }) {
  try {

    const { filters } = params;

    let { integrations, integrationsMode, ...rest } = filters;

    let incIntegration, excIntegration;
    if (integrationsMode === "INC") {
      incIntegration = integrations;
    } else {
      excIntegration = integrations;
    };

    const response = yield call(getDealerStoreDt, { ...params, filters: { incIntegration, excIntegration, ...rest } });
    yield put(getDealerStoreDtOk(response));
  } catch (error) {
    yield put(getDealerStoreDtErr(error));
  }
}

/********* LIST *********/

function* onGetDealerStoreList() {
  try {
    const response = yield call(getDealerStores);
    yield put(getDealerStoreListOk(response));
  } catch (error) {
    yield put(getDealerStoreListErr(error));
  }
}

function* onGetDealerGroupStoreList({ payload: { groupId } }) {
  try {
    const response = yield call(getDealerGroupStores, groupId);
    yield put(getDealerStoreListOk(response));
  } catch (error) {
    yield put(getDealerStoreListErr(error));
  }
}

function* onGetDealerRegionStoreList({ payload: { regionId } }) {
  try {
    const response = yield call(getDealerRegionStores, regionId);
    yield put(getDealerStoreListOk(response));
  } catch (error) {
    yield put(getDealerStoreListErr(error));
  }
}

/********** SUBSCRIPTION DATATABLE **********/

function* onGetSubscriptionDt({ payload: { params } }) {
  try {
    const response = yield call(getSubscriptionDt, params);
    yield put(getSubscriptionDtOk(response));
  } catch (error) {
    yield put(getSubscriptionDtErr(error));
  }
}

function* dealerStoreSaga() {
  yield takeEvery(GET_DEALER_STORE_DT, onGetDealerStoreDt);
  yield takeEvery(GET_DEALER_STORE_LIST, onGetDealerStoreList);
  yield takeEvery(GET_SUBSCRIPTION_DT, onGetSubscriptionDt);
  yield takeEvery(GET_DEALER_GROUP_STORE_LIST, onGetDealerGroupStoreList);
  yield takeEvery(GET_DEALER_REGION_STORE_LIST, onGetDealerRegionStoreList);
}

export default dealerStoreSaga;